<template>
    <div class="profile_account">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="container">
                <div class="row header-profile dis-screen-lg">
                    <div class="mb-6 header-mypf" style="margin-top: 15px;">
                        <h2 style="color: #253250 !important;">My Profile</h2>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="row">
                            <div class="col-lg-1 col-md-1 col-sm-1">

                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <div class="row align-center">
                                    <div>
                                        <img src="@/assets/profile/profile.svg" alt="" style="width: 85px; height: 85px; border-radius: 50%;">
                                    </div>
                                    <div class="ml-3">
                                       <h3>Tekken Son</h3> 
                                    </div>
                                </div>
                                <div class="row pt-3 pb-3 align-center justify-space-between">
                                    <div>
                                        <h4>Personal Information</h4> 
                                    </div>
                                    <div class="d-flex align-center cursor-pointer" @click="EditProfile()">
                                        <div>
                                            <img src="@/assets/profile/edit.svg" alt="">
                                        </div>
                                        <div class="ml-1" >
                                            <h3 style="color: #969EAE;">  Edit</h3> 
                                        </div>
                                      
                                    </div>
                                </div>
                                <div class="row header-title">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <p class="header-account">Full Name</p>
                                        <p class="value-row">Tekken Son</p>
                                        <p class="header-account">Nationality</p>
                                        <p class="value-row">Cambodian</p>
                                        <p class="header-account">Telephone Number</p>
                                        <p class="value-row">017577018</p>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <p class="header-account">Gender</p>
                                        <p class="value-row">Male</p>
                                        <p class="header-account">Email address</p>
                                        <p class="value-row">tekkenson@gmail.com</p>
                                        <p class="header-account">Date of Birth</p>
                                        <p class="value-row">2024-02-02</p>
                                       
                                    </div>
                                </div>
                                <div class="row header-title" style="margin-top: -20px;">
                                    <div class="col-lg-7 col-md-7 col-sm-7">
                                        <p class="header-account">Address</p>
                                        <span class="value-row" style="font-size: 13px;">Daun Penh Branch. Address: #248, Preah Monivong Blvd. (Street 93), Sangkat Boeung Raing, Khan Daun. Penh, Phnom Penh, Cambodia.</span>
                                        
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                   
                </div>
                <div class="mobile-screen header-profile">
                    <div class="mb-6" style="margin-left: 30px;">
                        <h2 style="color: #253250 !important;">My Profile</h2>
                    </div>
                    <div class="d-flex align-center justify-center" style="position: relative;">
                        <img v-if="selectedFile" :src="imageUrl" alt="" style="width: 140px; height: 140px; border-radius: 50%;">
                        <img v-else src="@/assets/profile/profile.svg" alt="" style="width: 140px; height: 140px; border-radius: 50%;">
                        <div class="edit_icon_image" @click="EditProfile()">
                            <img src="@/assets/profile/edit_icon.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <div class="col-12">
                            <div class="mb-4">
                                <h3>Personal Information</h3> 
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Full Name</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">Tekken Son</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Gender</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">Male</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Nationality</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">Cambodian</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Email address</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">tekkenson@gmail.com</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Telephone Number</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">017577018</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Date of Birth</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">2024-02-02</span>
                            </div>
                            <div class="mb-2">
                                <span class="header-account">Address</span>
                            </div>
                            <div class="mb-4">
                                <span class="value-row">Daun Penh Branch. Address: #248, Preah Monivong Blvd. (Street 93), Sangkat Boeung Raing, Khan Daun. Penh, Phnom Penh, Cambodia.</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-row justify="center">
            <v-dialog v-model="dialogEditpf" persistent max-width="600px" style="z-index: 1002; background-color: #FFFFFF !important; border-radius: 10px !important;">
                <v-card style="background-color: #FFFFFF !important; border-radius: 10px !important;">
                    <div class="container">
                        <div class="row d-flex justify-center align-center" style="margin-top:8px; padding-bottom: 7px; position: relative; border-bottom: 2px solid #C6C6C6;">
                            <span class="editpf">Edit Profile</span>
                            <div class="close-x" @click="CloseDialogPf">
                                <img src="@/assets/profile/x.svg" alt="">
                            </div>
                        </div>
                        <div class="row d-flex justify-center align-center" style="margin-top: 35px; position: relative;">
                            <img v-if="selectedFile" :src="imageUrl"   alt=""  class="img_edit">
                            <img v-else src="@/assets/profile/profile.svg" alt="" class="img_edit">
                            <div class="edit_icon"  @click="openFileInput">
                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange">
                                <img src="@/assets/profile/edit_icon.svg" alt="">
                            </div>
                        </div>
                        <div class="d-flex justify-center mt-8">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div>
                                            <input type="text"  class="custom-input equals_input" placeholder="Full Name" >
                                        </div>
                                        <div class="mt-3">
                                            <input type="text"  class="custom-input equals_input" placeholder="Nationality" >
                                        </div>
                                        <div class="mt-3">
                                            <input type="text"  class="custom-input equals_input" placeholder="Email" >
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 margin-mobile">
                                        <div>
                                            <input type="text"  class="custom-input equals_input" placeholder="Gender" >
                                        </div>
                                        <div class="mt-3">
                                            <input type="text"  class="custom-input equals_input" placeholder="Date of Birth" >
                                        </div>
                                        <div class="mt-3">
                                            <input type="text"  class="custom-input equals_input" placeholder="Telephone Number" >
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-center mt-3">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div>
                                            <textarea name="" class="custom-input-textarea" id="" placeholder="Address"></textarea>
                                        </div>
                                       
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-center mt-4">
                            <div class="set-width-80">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div>
                                            <v-btn  style="border-radius: 8px; width:  100%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize btn-width btn-width-mobile reposo-height">
                                                <span style="color: #FFF; font-size: 15px !important;"> Save </span>
                                            </v-btn>
                                        </div>
                                       
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <v-spacer></v-spacer>                        
                </v-card>
            </v-dialog>
        </v-row>
  </div>
</template>
<script>

    export default{
        data(){
            return {
                dialogEditpf:false,
                imageUrl: '',
                selectedFile: null,

            }
        },
        computed:{
            
        },
      
        methods:{
            openFileInput() {
                this.$refs.fileInput.click();
            },
            handleFileChange(event) {
                this.selectedFile = event.target.files[0];
                if (this.selectedFile) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.imageUrl = reader.result;
                        // this.uploadFile(this.imageUrl);
                    };
                    reader.readAsDataURL(this.selectedFile);
                }
            },
            CloseDialogPf(){
                this.dialogEditpf = false;
            },
            EditProfile(){
                this.dialogEditpf= true;
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
            
        },
       
        created(){
          this.scrollToTop();
        }
    }
</script>
<style scoped>
  .profile_account {
        padding-top: 55px;
      /* padding-bottom: 50px; */
  }
  .img_edit{
    width: 145px;
    height: 145px; 
    border-radius: 50%; 
    object-fit: contain;
    border: 1px solid #6A6969;
  }
  .dis-screen-lg{
    display: block;
  }
  .mobile-screen{
    display: none;
  }
  .set-width-80{
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .v-dialog{
      margin-top: 5% !important;
    }
  .header-profile{
    padding-top: 35px;
    padding-bottom: 70px;
    box-sizing: border-box;
  }
  .custom-input-textarea{
    padding-left: 12px;
    padding-top: 10px;
    border-radius: 10px;
    width: 100%;
    height: 80px;
    outline: none;
    border: 1px solid #C6C6C6;
    }
  .cursor-pointer{
    cursor: pointer;
  }
  .editpf{
    color: #1D1D1D !important; 
    font-weight: 600; 
    font-size:20px;
  }
  .close-x{
    position: absolute;
    right: 12px;
    top: 3px;
    cursor: pointer;
  }
  .custom-input {
    /* cursor: pointer; */
    padding-left: 12px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #C6C6C6;
    }
  
  .edit_icon{
    position: absolute;
    right: 38%;
    bottom: 12%;
    cursor: pointer;
  }
  .edit_icon_image{
    position: absolute;
    right: 38%;
    bottom: 12%;
    cursor: pointer;
  }
  .header-title{
    margin-left: -25px !important;
    margin-top: 0px;
  }
  .header-account{
    color: #969EAE;
  }
  .header-title p{
    margin-bottom: 3px !important;
  }
  .value-row{
    color: #424245;
  }

  @media screen and (width < 950px){
        /* .profile_account{
            margin-top:-35px;
        } */
        .header-mypf{
            margin-left: 70px;
        }
    }
    @media screen and (width < 620px){
        /* .profile_account{
            margin-top: -35px;
        } */
        .edit_icon{
            right: 37%;
        }
        .edit_icon_image{
            right: 37%;
        }
    }
    @media screen and (width < 600px){
        /* .profile_account{
            margin-top: -35px;
        }    */
        .edit_icon{
            right: 37%;
            
        }
        .edit_icon_image{
            right: 37%;
        }
        .margin-mobile{
            margin-top: -10px;
        }
        .dis-screen-lg{
            display: none;
        }
        .mobile-screen{
            display: block;
        }
    }
    @media screen and (width < 560px){
        .edit_icon{
            right: 35%;
        }
        .edit_icon_image{
            right: 35%;
        }
    }
    @media screen and (width < 530px){
        .edit_icon{
            right: 34%;
        }
        .edit_icon_image{
            right: 34%;
        }
    }
    @media screen and (width < 500px){
        /* .profile_account{
            margin-top: -35px;
        }    */
        /* .edit_icon{
            right: 148px;
        } */
    }
    @media(width < 480px) {
        .edit_icon{
            right: 33%;
        }
        .edit_icon_image{
            right: 33%;
        }
    }
    @media(width < 450px) {
        /* .profile_account{
            margin-top: -35px;
        } */
    }

    @media screen and (width < 438px){
        /* .profile_account{
            margin-top: -35px;
        } */
        .edit_icon{
            right: 32%;
        }
        .edit_icon_image{
            right: 32%;
        }

    }
    @media screen and (width < 395px){
        .edit_icon{
            right: 28%;
        }
        .edit_icon_image{
            right: 28%;
        }
    }
</style>