<template>
     <!-- <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div> -->
    
    <div class="travelpackage">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="containers" style="padding-bottom: 70px;">
                <div class="row mb-5 travelpackageheader">
                    <div class="col-lg-7 col-md-7 col-sm-7">
                        <div class="quest-package">
                            <h2>Why choose Travel Package?</h2>
                        </div>
                        <div class="ttitle-package">
                            <span>See why most of travelers choose  to experience as part of our strong and secure. Traveling Cambodia, Thailand and Vietnam. </span>
                        </div>
                        <div class="btn-package mt-4">
                            <v-btn style="border-radius: 4px; width:  35%; outline: none;" color="#938EBC" height="45px"  class="text-capitalize btn-width reposo-height">
                                <span style="color: #FFF; font-size: 16px !important;"> Learn more </span>
                            </v-btn>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5">
                        <img src="@/assets/travel_package/bus.png" alt="" width="100%" height="100%">
                    </div>
                </div>
                <div class="loading-travel" v-if="loading">
                    <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
                </div> 
                <div v-else>
                    <div v-if="listpacakge.length != 0">
                        <div class="row mb-3" v-for="(listinfo,i) in listpacakge" :key="i">
                            <div class="col-lg-7 col-sm-7 col-md-7 courser" @click="registerBuyPackagetravel(listinfo.id,listinfo.photo)" style="border-radius: 20px; overflow: hidden; border: 1px solid #C6C6C6; background: #FFFFFF;">
                                <div class="row">
                                    <div class="left-image-package">
                                        <img :src="listinfo.photo == '' ? require('@/assets/placeholder-image.jpeg') : listinfo.photo" alt="" width="100%"  height="100%">
                                    </div>
                                    <div class="right-image-package" style="display: flex; flex-direction: column; justify-content: space-between;">
                                        <div class="mt-lg-3 mt-md-3 mt-sm-3 ml-2 fw-600">
                                            <p>{{ listinfo.name }}</p>
                                        </div>
                                        <div class="ml-2 fw-600">
                                            <p style="color: #FF0000; font-size: 22px;">$   {{ listinfo.price }}</p>
                                        </div>
                                        <div class="ml-2">
                                            <p>{{ listinfo.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row mb-6" style="display: flex;align-items: center;justify-content: center;text-align: center;">
                            <div style="display: flex;align-items: center;justify-content: center;text-align: center;" class="set-heights">
                                <div>
                                    <img src="@/assets/icon-social-media/not_found.jpg" alt="">
                                    <p class="no-result">Data Not Found!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import axios from 'axios';
  export default{
      data(){
          return{
            listpacakge:[],
            loading: true,
          }
      },
      methods:{
        registerBuyPackagetravel(id,photo){
            // console.log("testing");
            this.$router.push({ 
                path: '/register-buy-package',
                query: { 
                    id: id,
                    photo: photo,
                } 
            });
            // this.$router.push("/register-buy-package");
        },
        async getListTravelPackage() {
            this.loading = true;
            const url = this.$url + `travel-package/list`;
            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };
        
            axios(config).then((response) => {
                if (response.status == 200) {
                    this.loading = false;
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.listpacakge = response.data.body;
                    }
                } 
            }).catch((error) => {
                console.log(error);
            });
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
      },
      created(){
            this.scrollToTop();
            setTimeout(() => {
                this.getListTravelPackage();
            }, 1000);
          // localStorage.setItem("handleBrowser", 1);
      }
  }
</script>
<style scoped>
  .travelpackage {
        padding-top: 55px;
    
  }
  .left-image-package{
    width: 40%;
  }
  .right-image-package{
    width: 60%;
  }
  .travelpackageheader{
      padding-top: 35px;
      /* padding-bottom: 70px; */
      /* box-sizing: border-box; */
  }
  .no-result {
    margin-top: 15px;font-size: 25px;font-weight: 600;
  }
  .ttitle-package{
        margin-top: 19px;
  }
  .quest-package{
        margin-bottom: 10px; 
        font-size: 17px;
  }
  .loading-travel {
    text-align: center;
    line-height: 40vh;
    height: 40vh;
    z-index: 100000;
}
  .quest-package h2{
    color: #312783;
    
  }
  .right-image-package p{
        margin-bottom: 2px !important;
      }
  .courser{
    cursor: pointer;
  }
  .fw-600{
    font-weight: 600;
  }
  .mt-price{
    margin-top: 84px;
    font-weight: bold;
  }

  @media screen and (width < 950px){
      /* .travelpackage {
          margin-top: -20px;
      } */
      .right-image-package p{
        margin-bottom: 2px !important;
        font-size: 10px;
      }

  }
 
  @media screen and (width < 500px){
      /* .travelpackage {
          margin-top: -20px;
      } */
      .right-image-package p{
        margin-bottom: 2px !important;
      }
     
      .travelpackageheader{
          padding-top: 20px;
          padding-bottom: 40px;
          opacity: 1;
      }
  }

  @media screen and (width < 438px){
      /* .travelpackage {
          margin-top: -20px;
      } */

      .travelpackageheader{
          padding-top: 20px;
          padding-bottom: 40px;
          opacity: 1;
      }
     
  }
</style>