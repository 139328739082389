<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
        <HeaderComponent />
        <div class="wing-payment">
            <div class="containers">
                <div class="set-heights" style="" v-if="showProcess">
                    <h1>Wing Payment Processing...</h1>
                </div>
                <div v-else class="set-heights">
                    <h1 style="margin-bottom: 16px;">Wing Payment failed</h1>
                    <span @click="back" style="cursor: pointer;;background: #DE5D0A;padding: 10px 15px;color: #fff;border-radius: 1px;">Back</span>
                </div>
            </div>
        </div>
        <IncFooter />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import axios from 'axios';
import qs from 'qs';
export default {
    components: { HeaderComponent, IncFooter },
    data() {
        return {
            loading: false,
            showProcess: false,
            info: [],
            transactionId: this.$route.query.transactionId
        }
    },
    methods: {
        async paymentComplete() {
            const data = {
                'transactionId': this.$route.query.transactionId
            }
            const url = this.$url + `booking/complete`;
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url               
            };
            this.loading = true;
            axios(config).then((response) => {
                this.loading = false;
                if (response.status == 200) {
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {  
                        this.info = response.data.body;
                        if(this.info.status == 1){ // Complete
                            this.$router.push({
                                path: "/payment-success",
                                query: {
                                    transactionId: this.$route.query.transactionId
                                }
                            });
                        } else {
                            this.showProcess = false;
                        }
                    } else {
                        this.showProcess = false;
                    }
                }
            }).catch((error) => {console.log(error);});
        },
        back() {
            this.$router.push("/");
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
    created() {
        this.scrollToTop();
        setTimeout(() => {
            this.paymentComplete();
        }, 1000);
        if(this.transactionId != '') {
            this.showProcess = true;
        }else{
            this.showProcess = false;
        }
    },
    beforeUnmount() {
        window.onpopstate = null;
    },
}
</script>

<style scoped>
    .wing-payment {
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .set-heights {
        height: 310px;
    }
    
    @media(min-width: 1740px) {
        .set-heights {
            height: 510px;
        }
    }
</style>