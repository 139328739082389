<template>
    <div>
        <HeaderComponent />
        <div class="trip-info">
            <v-row justify="space-around">
                <v-col cols="12" sm="10">
                    <v-sheet class="py-4 px-1">
                        <v-chip-group mandatory active-class="orangered--text">
                            <v-chip @click="descripShow">{{ $t('message.tripInfo') }}</v-chip>
                            <v-chip @click="slideShow">{{ $t('message.gallery') }}</v-chip>
                            <v-chip @click="getMapShowBoard">{{ $t('message.boarding') }}</v-chip>
                            <v-chip @click="getMapShowDrop">{{ $t('message.dropOff') }}</v-chip>
                            <v-chip @click="showAmenity">{{ $t('message.amenities') }}</v-chip>
                        </v-chip-group>
                    </v-sheet>
                </v-col>
            </v-row>
            <div class="containers">
                <!-- <div class="description" v-if="desHow">
                  
                    <div style="padding-bottom: 30px;" v-for="(paragraph, index) in descrip" :key="index" v-html="paragraph"></div>
                </div> -->
                <div class="description" v-if="desHow">
                    <p class="description">{{ $t('message.description') }}</p>
                    <div v-for="(paragraph, index) in descrip" :key="index" v-html="paragraph"
                        style="padding-bottom: 30px;">
                    </div>
                </div>


                <div class="slide-carousel description" v-if="slideShows">
                    <div>
                        <div class="noSide" v-if="slide">
                            <img :src="transportationPhoto == '' ? require('@/assets/placeholder-image.jpeg') : transportationPhoto"
                                alt="">
                        </div>
                        <div class="slider" v-else>
                            <vueper-slides :arrows="true" height="100%" style="height: 100%;border-radius: 12px;">
                                <vueper-slide style="height: 100%;border-radius: 12px;" v-for="(slide, i) in slides"
                                    :key="i" :image="slide.photo" />
                            </vueper-slides>
                        </div>
                    </div>
                </div>
                <div v-if="boradingShowMap">
                    <ul>
                        <li v-for="(boardinglist, i) in boardingPointList" :key="i" style="padding-bottom: 60px;">
                            {{ boardinglist.address }}
                            <div class="map" ref="mapboading"
                                v-html="generateMapUrl(boardinglist.lats, boardinglist.longs)">

                            </div>
                        </li>
                    </ul>
                    <!-- <div class="map" ref="mapdropoff" v-html="linkMapB">
                        
                    </div> -->

                </div>
                <div v-if="DropShowMap">
                    <ul>
                        <li v-for="(dropOffPoint, i) in dropOffPointList" :key="i" style="padding-bottom: 60px;">
                            {{ dropOffPoint.address }}
                            <div class="map" ref="mapdropoff"
                                v-html="generateMapUrl(dropOffPoint.lats, dropOffPoint.longs)">

                            </div>
                        </li>
                    </ul>
                    <!-- <div class="map" ref="mapdropoff" v-html="linkMapD">
                        
                    </div> -->

                </div>
                <div class="amenities description" v-if="amenShow">
                    <div class="grid-container">
                        <div class="grid-item" v-for="(amenitie, i) in amenities" :key="i">
                            <img :src="amenitie.icon" style="width: 35px;height: 35px;" alt="">
                            <div class="text">{{ amenitie.name }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="footer">
            <IncFooter />
        </div>
    </div>

</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue'
import IncFooter from '@/components/footer/IncFooter.vue'
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
export default {
    components: {
        HeaderComponent,
        VueperSlides,
        VueperSlide,
        IncFooter
    },
    data() {
        return {
            // description
            desHow: true,
            descrip: "",

            // Slide
            slide: false,
            slideShows: false,
            slides: [],
            itemsSide: [],
            transportationPhoto: [],

            // borading & drop off
            boradingShowMap: false,
            DropShowMap: false,
            linkMapB: "",
            linkMapD: "",

            amenShow: false,
            amenities: [],
            dropOffPointList: [],
            boardingPointList: [],

        }
    },
    methods: {
        generateMapUrl(latitude, longitude) {
            // console.log(latitude);
            return `<iframe src = "https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
        },
        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        descripShow() {
            this.slideShows = false;
            this.boradingShowMap = false;
            this.DropShowMap = false;
            this.amenShow = false;
            this.desHow = true,
                this.descrip = this.getParagraphs(this.$route.params.decri);
        },
        slideShow() {
            this.desHow = false,
                this.boradingShowMap = false;
            this.DropShowMap = false;
            this.amenShow = false;
            this.slideShows = true;
            this.slides = this.$route.params.slides;
            if (this.slides != 0) {
                this.slide = false;
                this.itemsSide = this.slides;
            } else {
                this.slide = true;
                this.transportationPhoto = this.$route.params.trans;
            }
        },
        getMapShowBoard() {
            this.desHow = false,
                this.slideShows = false;
            this.DropShowMap = false;
            this.amenShow = false;
            this.boradingShowMap = true;
            this.boardingPointList = this.$route.params.boardingPointList;
            this.linkMapB = `<iframe src = "https://maps.google.com/maps?q=${this.$route.params.boardingLats},${this.$route.params.boardingLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.$nextTick(() => {
            //     // Access the element and set its innerHTML
            //     this.$refs.mapboading.innerHTML = this.linkMapB;
            // });
            // this.linkMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + this.$route.params.boardingLats + ',' + this.$route.params.boardingLongs;
        },
        getMapShowDrop() {
            this.desHow = false,
                this.slideShows = false;
            this.boradingShowMap = false;
            this.amenShow = false;
            this.DropShowMap = true;
            this.dropOffPointList = this.$route.params.dropOffPointList;
            this.boardingPointList = this.$route.params.boardingPointList;

            this.linkMapD = `<iframe src = "https://maps.google.com/maps?q=${this.$route.params.dropOffLats},${this.$route.params.dropOffLongs}&hl=es;z=14&amp;output=embed" style="border: none; height: 400px; width: 100%;"></iframe>`;
            // this.$nextTick(() => {
            //     // Access the element and set its innerHTML
            //     this.$refs.mapdropoff.innerHTML = this.linkMapD;
            // });
            // this.linkMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513@" + this.$route.params.dropOffLats + ',' + this.$route.params.dropOffLongs ;
        },
        showAmenity() {
            this.desHow = false,
                this.slideShows = false;
            this.boradingShowMap = false;
            this.DropShowMap = false;
            this.amenShow = true;
            this.amenities = this.$route.params.amen;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        this.descripShow();
        this.scrollToTop();
    },
    mounted() {

    }
}
</script>

<style scoped>
/* footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  height: 50px;
} */

.trip-info {
    padding-top: 65px;
    padding-bottom: 50px;
    width: 100%;
}

.description {
    padding-top: 10px;
    /* height: 772px; */
    overflow-y: auto;
}

.vueperslide__title {
    font-size: 36px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 15%;
}

.description p {
    font-size: 15px;
}

.slider {
    margin-top: 20px;
    width: 100%;
    height: 240px;
}

.noSide {
    margin-top: 10px;
    font-size: 30px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.noSide img {
    width: 100%;
    height: 100%;
}

.map {
    height: 360px;
    margin-top: 15px;
    margin-left: -16px;
}

.amenities {
    width: 100%;
    /* border: 1px solid; */
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* @media screen and (width < 1200px) {
        .description {
        height: 130vh;
    }
        
    }
    @media screen and (width < 1100px) {
        .description {
        height: 135vh;
    }
        
    }
    @media screen and (width < 1000px) {
        .description {
        height: 140vh;
    }
        
    }
    @media screen and (width < 970px) {
        .description {
        height: 150vh;
    }
    }
    @media screen and (width < 795px) {
        .description {
        height: 155vh;
    }
    }
    @media screen and (width < 720px) {
        .description {
        height: 160vh;
    }
    }
    @media screen and (width < 640px) {
        .description {
        height: 165vh;
    }
    }
    @media screen and (width < 590px) {
        .description {
        height: 170vh;
    }
    }
    @media screen and (width < 560px) {
        .description {
        height: 175vh;
    }
    }
    @media screen and (width < 530px) {
        .description {
        height: 180vh;
    }
    } */
</style>