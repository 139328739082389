<template>
      <div class="forgotscreen">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="containers">
            <div class="row header-login">
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                
                </div>
                <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12" style="background: #FFFFFF; border-radius: 15px;">
                   
                    <h2 class="text-center acount-header">{{$t('message.forgotPassword')}}</h2>
                    <p class="login-paragraph text-center">{{$t('message.pleaseEnterYourEmailTelephoneNumber')}}</p>
                    <div class="mb-4 mt-2 emailmargin">
                        <label class="inner-block"><span style="color: #FF0000;">*</span>{{$t('message.emailTelephoneNumber')}}</label>
                        <input type="text" v-model="userForm.email_or_phone" :class="{ 'is-invalid': isForgetPassword && $v.userForm.email_or_phone.$error }" class="form-control form-control-lg form-input-login" :placeholder="$t('message.emailTelephoneNumber')"/>
                        <div v-if="isForgetPassword && !$v.userForm.email_or_phone.required" class="invalid-feedback">{{$t('message.yourEmailTelephoneNumberRequired')}}</div>
                    </div>
                    
                    <div class="mb-2 mt-2 emailmargin">
                        <v-btn :disabled="disabled" @click="addforgetPassword()" style="border-radius: 4px; width:  91%; outline: none;" color="#DE5D0A" height="50px"  class="text-capitalize btn-width reposo-height">
                            <span style="color: #FFF; font-size: 22px !important;"> {{ $t(titleforgetPassword) }} </span>
                        </v-btn>
                        
                    </div>
                       
                    
                </div>
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                    
            </div>
        </div>
        </div>
        </div>
        
    </div>
</template>
<script>
import {
      required
  } from "vuelidate/lib/validators";
import axios from "axios";
    export default{
        data(){
            return{
                userForm: {
                  email_or_phone: "",
                },
                isForgetPassword: false,
                titleforgetPassword:'message.continue',
                disabled:false,
            }
        },
        validations:{
            userForm: {
                  email_or_phone: {required},
              },
        },
        methods:{
            scrollToTop() {
                window.scrollTo(0,0);
            },
            async addforgetPassword(){
                this.isForgetPassword = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }else{
                    this.titleforgetPassword = 'Loading';
                    this.disabled = true;
                    localStorage.setItem('emailUser',  this.userForm.email_or_phone);
                    
                    const data = {
                        'username'          : this.userForm.email_or_phone,
                    }
                    const url = this.$url + `user-register/forgot-password`;
                    let config = {
                        method: "POST",
                        headers: {
                            ...this.$header,
                            "Content-Type": "multipart/form-data"
                        },
                        data: data,
                        url,
                    };
                    axios(config).then((response) => {
                        if (response.status == 200) {
                            this.titleforgetPassword = 'message.continue';
                            this.disabled = false;
                            if (response.data.header.result == true && response.data.header.statusCode == 200) {
                                if(response.data.body.status == true) {
                                    let token = response.data.body.message;
                                    localStorage.setItem('tokenUser',token);
                                   
                                    this.$router.push("/comfirm-otp");
                                }else{
                                    alert(response.data.body.message);
                                }
                            }else {
                                alert("Please Input Information");
                            }
                        } 
                    }).catch((error) => {
                        console.log(error);
                    });
                    
                }
                
            }
        },
        created(){
            localStorage.setItem('handleRemamberPage',2); //1 register user 2 forgot page       
            this.scrollToTop();
            // localStorage.setItem("handleBrowser", 1);
        }
    }
</script>
<style scoped>
    .forgotscreen {
        padding-top: 55px;
        /* padding-bottom: 50px; */
        margin-top: 28px;
      
    }
    .acount-header{
        margin-top: 15px;
        
    }
    .invalid-feedback{
        color: #FF0000;
    }
    .is-invalid{
        border: 1px solid #FF0000 !important;
    }
    .emailmargin{
        margin-left: 40px;
    }
    .header-login{
        padding-top: 35px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .login-paragraph{
        color: #6A6969;
        font-size: 13px;
    }
    label {
        font-weight: bold;
    }
    .inner-block{
        display: block;
        width: 92%;
        margin-bottom: 4px;
    }
    .form-input-login{
        /* width: 100%; */
        width: 91%;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 45px;
        outline: none;
        padding-left: 12px;
    }
    .forgetPasswordform{
        width: 91%;
        text-align: right;
    }

    .btn-width{
        width: 91%;
        border-radius: 4px;
        background-color: #DE5D0A;
        /* z-index: 10000; */
    }
  
    @media(min-width: 1740px) {
        
    }
    
    @media(width < 1024px) {
    
    }
    @media screen and (width < 950px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        

    }
    @media screen and (width < 620px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media screen and (width < 500px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media(width < 450px) {
        /* .forgotscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        
    }

    @media screen and (width < 438px){
        /* .forgotscreen {
            margin-top: -20px;
        } */


        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
</style>