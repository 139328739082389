<template>
    <div class="banners">
        <div class="banners_image">
            <img src="@/assets/banners/app-v1.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.banners {
    height: 400px;
}

.banners_image {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #DE5D0A 0%, #322783 100%);
}

.banners_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (width < 450px) {
    .banners {
        display: none;
    }
}
</style>