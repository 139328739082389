<template>
  <div class="gallers">
    <div class="loading" v-if="loading">
      <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
      <header-component/>
      <div class="why-partner">
        <div class="containers">
          <div class="vet_men">{{$t('message.whyPartnerWithUs')}}</div>
          <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="box">
                <span>{{$t('message.saveTime')}}</span>
                <ul>
                  <li>{{$t('message.saveTime1')}}</li>
                  <li>{{$t('message.saveTime2')}}</li>
                  <li>{{$t('message.saveTime3')}}</li>
                  <li>{{$t('message.saveTime4')}}</li>
                  <li>{{$t('message.saveTime5')}}</li>
                  <li>{{$t('message.saveTime6')}}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="box">
                <span>{{$t('message.competitiveRate')}}</span>
                <ul>
                  <li>{{$t('message.competitiveRate1')}}</li>
                  <li>{{$t('message.competitiveRate2')}}</li>
                  <li>{{$t('message.competitiveRate3')}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="box">
                <span>{{$t('message.provincialCity25')}}</span>
                <ul>
                  <li>{{$t('message.provincialCity25List1')}}</li>
                  <li>{{$t('message.provincialCity25List2')}}</li>
                  <li>{{$t('message.provincialCity25List3')}}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="box">
                <span>{{$t('message.safetyCerification')}}</span>
                <ul>
                  <li>{{$t('message.safetyCerificationList1')}}</li>
                  <li>{{$t('message.safetyCerificationList2')}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <inc-footer/>
    </div>
  </div>
</template>
    
<script>
  import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
  import IncFooter from '@/components/footer/IncFooter.vue';
  export default {
    components: {
      HeaderComponent,
      IncFooter
    },
    data() {
      return {
        loading: true,
        reloadGallers: "",
      };
    },
    methods: {
      scrollToTop() {
         window.scrollTo(0,0);
    },
    },
    created() {
      localStorage.setItem("reloadHome", "false");
      localStorage.setItem("reloadCompanyProfile", "false");
      localStorage.setItem("reloadRetal", "false");
      localStorage.setItem("reloadRestore", "false");
      localStorage.setItem("reloadBlog", "false");
      localStorage.setItem("reloadTerm", "false");
      localStorage.setItem("reloadPrivacy", "false");
      
      this.scrollToTop();
      this.reloadGallers = localStorage.getItem("reloadGallers");
      if (this.reloadGallers != "true") {
        localStorage.setItem("reloadGallers", "true");
      }
      if (localStorage.getItem("reloadGallers") == "true") {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  };
</script>
    
<style scoped>
  .why-partner {
    padding-top: 120px;
    padding-bottom: 50px;
  }
  .vet_men {
    color: #DE5D0A;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }
  .box{
    height: 200px;
    border-radius: 12px;
    padding: 20px;
    background: #E5A880;
  }
  .box span {
    font-size: 20px;
    font-weight: 600;
    color: #1D1D1D;
  }
  @media (width < 1087px) {
    .box{
      height: 240px;
    }
  }
</style>