import { render, staticRenderFns } from "./ResetNewPasswordUser.vue?vue&type=template&id=d8b57534&scoped=true"
import script from "./ResetNewPasswordUser.vue?vue&type=script&lang=js"
export * from "./ResetNewPasswordUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8b57534",
  null
  
)

export default component.exports