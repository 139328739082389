<template>
    <div>
        <div class="loading" v-if="loading"><v-progress-circular indeterminate color="#de5d0a"></v-progress-circular></div>
        <div v-else class="choose-box">
            <div class="select-seat containers">
                <div class="seat-available">
                    <img :src="seatType == 2 ? require('@/assets/seats/bus_night_available.png'): require('@/assets/seats/available.svg')" width="30px" alt="">
                    <div>Available</div>
                </div>
                <div class="selected">
                    <img :src="seatType == 2 ? require('@/assets/seats/bus_night_selected.png') : require('@/assets/seats/selected.svg')" width="30px" alt="">
                    <div>Selected</div>
                </div>
                <div class="seat-unavailable">
                    <img :src="seatType == 2 ? require('@/assets/seats/bus_night_not_available.png') : require('@/assets/seats/not_available.svg')" width="30px" alt="">
                    <div>Unavailable</div>
                </div>
            </div>
            <div class="container-table containers">
                <table class="table_reposive">
                    <tr v-for="(colunm ,index) in colunms" :key="index">
                        <td v-for="(row, index1) in arraydata[index]['col'].length" :key="index1" style="text-align: center;width: 30px;">
                            <div v-if="arraydata[index]['col'][index1]['label'] != ''" style="margin: 0px;">
                                <div v-if="!(arraydata[index]['col'][index1]['label'] == 'Down Stair' || arraydata[index]['col'][index1]['label'] == 'Up Stair' || arraydata[index]['col'][index1]['label'] == 'Capitain' || arraydata[index]['col'][index1]['label'] == 'Toilet' || arraydata[index]['col'][index1]['label'] == 'Hostess' || arraydata[index]['col'][index1]['label'] == 'Door')">
                                    <div v-if="!(getUnva(arraydata[index]['col'][index1]['value']))">
                                        <img v-if="!selectedSeat || !(getSeat(arraydata[index]['col'][index1]['value']))" @click="addClick(arraydata[index]['col'][index1]['value'],arraydata[index]['col'][index1]['label'])" :src="seatType == 2 ? require('@/assets/seats/bus_night_available.png') : require('@/assets/seats/available.svg')" style="width:40px" alt="">
                                    </div>
                                </div>
                                <img v-if="getUnva(arraydata[index]['col'][index1]['value'])" :src="seatType == 2 ? require('@/assets/seats/bus_night_not_available.png')  : require('@/assets/seats/not_available.svg')" style="width:40px" alt="">
                                <img v-if="selectedSeat && getSeat(arraydata[index]['col'][index1]['value'])"  @click="deleteSeat(arraydata[index]['col'][index1]['value'],arraydata[index]['col'][index1]['label'])" :src="seatType == 2 ? require('@/assets/seats/bus_night_selected.png') : require('@/assets/seats/selected.svg')" style="width:40px" alt="">
                            </div>
                            <img v-if="arraydata[index]['col'][index1]['label'] == ''" :src="require('@/assets/seats/invisible.svg')" style="width:20px" alt="">
                            <p v-if="!(arraydata[index]['col'][index1]['label'] == 'Hostess' || arraydata[index]['col'][index1]['label'] == 'Capitain')" style="font-size: 12px;color: #000000;font-weight: 600; margin-top: -10px;margin-bottom: 0;">{{ arraydata[index]['col'][index1]['label']}} <span>{{getGender(arraydata[index]['col'][index1]['value'])}}</span></p>
                            <img v-if="arraydata[index]['col'][index1]['label'] == 'Capitain'" :src="require('@/assets/seats/captain.png')" style="width:30px;" alt="">
                            <img v-if="arraydata[index]['col'][index1]['label'] == 'Hostess'" :src="require('@/assets/seats/hostess.png')" style="width:30px;" alt="">
                        </td>
                    </tr>
                </table>
                <div class="btn_edits_seat">
                    <div class="continue" @click="getBtnthen">
                        Continue
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import axios from "axios";
export default {
    data() {
        return {
            loading: true,
            info: null,
            errored: false,
            rows: 0,
            colunms: 0,
            arraydata: [],
            unavailables: [],
            gender: [],
            seats: [],
            seatname: [],
            selectedSeat: false,
            unava: false,
            seatType:'',
        }
    },
    methods: {
        getBtnthen() {     
            localStorage.setItem('seatback', this.seats);
            localStorage.setItem('seatlabelback', this.seatname);
            this.$router.push("/passenger-info");
        },
        addClick(value, label) {
            this.selectedSeat = true;
            this.seats.push(value);
            this.seatname.push(label);

            let calTotal = this.seats.length;
            let seatP = localStorage.getItem('priceback');
            let totalAmount = parseFloat(calTotal * seatP);
            this.resultTotal = totalAmount;
            localStorage.setItem('resultTotalBack', this.resultTotal);
        },
        getUnva(item) {
            let check = true; 
            for(let j = 0; j < this.unavailables.length; j++) {

                if(item == this.unavailables[j]) {
                    check = true
                    break;
                }else{
                    check = false;
                }
            }
            if(this.unavailables.length == 0){
                return false;
            }
            return check;
        },
        getGender(item) {
            let genStr = ""; 
            for(let j = 0; j < this.unavailables.length; j++) {
                if(item == this.unavailables[j]) {
                    if(this.gender[j] == '1') {
                        genStr = "(M)";
                    }else{
                        genStr = "(F)";
                    }
                    break;
                }else{
                    genStr = "";
                }
            }
            if(this.unavailables.length == 0){
                return genStr;
            }
            return genStr;
        },
        getSeat(item) {
            let check = true; 

            for(let j = 0; j < this.seats.length; j++) {
        
                if(item == this.seats[j]) {
                    check = true
                    break;
                }else{
                    check = false;
                }
            }

            return check;
        },
        deleteSeat(value, label) {

            if(this.seats.length == 1) {
                this.selectedSeat = false;
            }

            for(let j = 0; j < this.seats.length; j++) {
                if(this.seats[j] == value) { this.seats.splice(j, 1); }
            }

            for(let j = 0; j < this.seatname.length; j++) {
                if(this.seatname[j] == label) { 
                    this.seatname.splice(j, 1); 
                }
            }

            if(this.seats.length == 0){ this.selectedSeat = false; }

            if(this.seatname.length == 0){ this.selectedSeat = false; }

        },
        
        // Get Data From Api Layout
        async getDateLayout() {
        
            const data = {'date': localStorage.getItem('dateTo'),'journey': localStorage.getItem('journeyidback')};
            const url = this.$url + `seat/layout`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then(response => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.loading = false;
                        this.info = response.data.body;
                        this.seatType = this.info[0]['seatType'];
                        let layout = JSON.parse(this.info[0]['layout']);
                            
                        this.arraydata = JSON.parse(this.info[0]['layout']);
                        this.colunms = layout.length;

                            let colspan = [];
                            for(let i = 0; i < this.arraydata[0]['col'].length; i++) {
                                colspan.push(this.arraydata[0]['col'][i]);
                                if(this.arraydata[0]['col'][i]['attr']['colspan'] != ''){   
                                    for(let j = 0; j < this.arraydata[0]['col'][i]['attr']['colspan'] - 1; j++) {
                                        colspan.push({
                                            attr: {
                                                colspan: '',
                                                rowspan: '',
                                            },
                                            value: '',
                                            label: ''
                                        });
                                    }
                                }
                            }
                            this.arraydata.splice(0, 1, {col: colspan, row: ''})
                            this.rows = layout[0]['col'].length;
                            this.getDataUnavailable();
                    }else{
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },

        // Get Data From Api Unavailable
        async getDataUnavailable() {
            const data = {'date': localStorage.getItem('dateTo'),'journey': localStorage.getItem('journeyidback')};
            const url = this.$url + `seat/unavailable`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then(response => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        let data = response.data.body;
                        let size = response.data.body.length;
                        
                        if(response.data.body != 0) {
                            for(let i = 0; i < size; i++) {
                                this.unavailables.push(data[i]['seatNumber']);
                                this.gender.push(data[i]['gender']);
                            }
                        }

                        if(localStorage.getItem('seatback') != null) {

                            let arrayNew = localStorage.getItem('seatback');
                            let seatArray = arrayNew.split(",");

                            for(let i = 0 ; i < seatArray.length; i++) {

                                if(seatArray[i] != ',') {
                                    this.seats.push(seatArray[i]);
                                }
                            
                            } 
                            this.selectedSeat = true;
                        }

                        if(localStorage.getItem('seatlabelback') != null) {

                            let arrayNew = localStorage.getItem('seatlabelback');
                            let seatArray = arrayNew.split(",");

                            for(let i = 0 ; i < seatArray.length; i++) {

                                if(seatArray[i] != ',') {
                                    this.seatname.push(seatArray[i]);
                                }
                            
                            } 
                            this.selectedSeat = true;
                        }

                    }else{
                        this.errored = true
                    }

                }
            }).catch(error => {console.log(error);})
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    created() {
        this.scrollToTop();
        if(localStorage.getItem("seatback") == null && localStorage.getItem("seatlabelback") == null) {
            this.selectedSeat = false;
        }
        setTimeout(() => {
            this.getDateLayout();
        }, 1000);
    }
}
</script>

<style scoped>
    .choose-box {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .container-table {
        width: 100%;
    }
    .table_reposive {
        width: 100%;
    }
    .btn_edits_seat {
        margin-top: 20px;
        width: 100%;
    }
    .btn_edits_seat .continue {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        background: #DE5D0A;
    }
    .select-seat {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
    }
    .select-seat .seat-available, .selected, .seat-unavailable {
        width: 33.33%;
        display: flex;
        align-items: center;
    }
    .select-seat .seat-available, .selected, .seat-unavailable div {
        font-size: 14px;
        font-weight: 600;
        color: #1D1D1D;
    }
</style>