<template>
      <div class="loginscreen">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="containers">
                <div class="row header-login">
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                    
                    </div>
                    <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12" style="background: #FFFFFF; border-radius: 15px;">
                        <form>
                            <h2 class="text-center acount-header">{{$t('message.loginAccount')}}</h2>
                            <p class="login-paragraph text-center">{{$t('message.pleaseEnterYourCredentialToLogin')}}</p>
                            <div class="mb-4 mt-2 emailmargin">
                                <label class="inner-block"><span style="color: #FF0000;">*</span>{{$t('message.emailTelephoneNumber')}}</label>
                                <input type="text" v-model="userForm.email_or_phone" :class="{ 'is-invalid': isLogin && $v.userForm.email_or_phone.$error }" class="form-control form-control-lg form-input-login" :placeholder="$t('message.emailTelephoneNumber')"/>
                                <div v-if="isLogin && !$v.userForm.email_or_phone.required" class="invalid-feedback">{{$t('message.yourEmailTelephoneNumberRequired')}}</div>
                            </div>
                            <div class="mb-3 mt-2 emailmargin">
                                <label class="inner-block"><span style="color: #FF0000;">*</span>{{$t('message.enterPassword')}}</label>
                                <input type="password"  v-model="userForm.password" autocomplete="off" :class="{ 'is-invalid': isLogin && $v.userForm.password.$error }" class="form-control form-control-lg form-input-login" :placeholder="$t('message.enterPassword')"/>
                                <div v-if="isLogin && !$v.userForm.password.required" class="invalid-feedback">{{$t('message.yourPasswordRequired')}}</div>
                            </div>
                            <div class="mb-4 mt-2 emailmargin forgetPasswordform">
                                <label class="inner-block"><router-link to="/forgot-password"><span style="color: #FF0000; font-weight: 600 !important;">{{$t('message.forgotPassword')}}</span></router-link></label>
                            
                            </div>
                            <div class="mb-2 mt-2 emailmargin">
                                <v-btn :disabled="disabled" @click="handleLogin" style="border-radius: 4px; width:  91%; outline: none;" color="#DE5D0A" height="50px"  class="text-capitalize btn-width reposo-height">
                                    <span style="color: #FFF; font-size: 22px !important;"> {{ $t(titleLogin) }} </span>
                                </v-btn>
                                
                            </div>
                            <div class="mb-6 mt-2 emailmargin">
                                <p class="login-paragraph text-center" style="font-size: 16px;">{{$t('message.donotHaveAccountRegister')}}<router-link to="/register"> <span style="color: #DE5D0A;">{{$t('message.register')}}</span> </router-link></p>
                                
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                        
                </div>
                
            </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import {
      required
  } from "vuelidate/lib/validators";
import axios from "axios";
    export default{
        data(){
            return{
                userForm: {
                  email_or_phone: "",
                  password: "",
              },
              isLogin: false,
              disabled:false,
              titleLogin: 'message.login',
            }
        },
        validations:{
            userForm: {
                  email_or_phone: {required},
                  password: {required},
              },
        },
        methods:{
            handleLogin() {
              this.isLogin = true;
              this.$v.$touch();
              if (this.$v.$invalid) {
                  return;
              }else{
                this.disabled=true;
                this.titleLogin = "Loading";
                const data = {
                    'username'           : this.userForm.email_or_phone,
                    'password'           : this.userForm.password,
                }
                const url = this.$url + `user-register/login`;
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        this.disabled=false;
                        this.titleLogin = 'message.login';
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if(response.data.body.status == true) {
                                setTimeout(() => {
                                    this.$router.push("/");
                                }, 1500);
                            }else{
                                alert(response.data.body.message);
                            }
                        }else {
                            alert("Please Input Information");
                        }
                    } 
                }).catch((error) => {
                    console.log(error);
                });
              }
              
            },
            scrollToTop() {
                window.scrollTo(0,0);
            },
        },
        created(){
            this.scrollToTop();
            // localStorage.setItem("handleBrowser", 1);
        }
    }
</script>
<style scoped>
    .loginscreen {
        padding-top: 55px;
        /* padding-bottom: 50px; */
        margin-top: 28px;
      
    }
    .invalid-feedback{
        color: #FF0000;
    }
    .is-invalid{
        border: 1px solid #FF0000 !important;
    }
    .acount-header{
        margin-top: 15px;
        
    }
    .emailmargin{
        margin-left: 40px;
    }
    .header-login{
        padding-top: 35px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .login-paragraph{
        color: #6A6969;
        font-size: 13px;
    }
    .login-paragraph span{
        padding-left: 6px;
    }
    label {
        font-weight: bold;
    }
    .inner-block{
        display: block;
        width: 92%;
        margin-bottom: 4px;
    }
    .form-input-login{
        /* width: 100%; */
        width: 91%;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 45px;
        outline: none;
        padding-left: 12px;
    }
    .forgetPasswordform{
        width: 91%;
        text-align: right;
    }

    .btn-width{
        width: 91%;
        border-radius: 4px;
        background-color: #DE5D0A;
        /* z-index: 10000; */
    }
  
    @media(min-width: 1740px) {
        
    }
    
    @media(width < 1024px) {
    
    }
    @media screen and (width < 950px){
        /* .loginscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        

    }
    @media screen and (width < 620px){
        /* .loginscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media screen and (width < 500px){
        /* .loginscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media(width < 450px) {
        /* .loginscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        
    }

    @media screen and (width < 438px){
        /* .loginscreen {
            margin-top: -20px;
        } */


        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
</style>