<template>
  <div>
    <HeaderComponent />
    <div class="boarding">
      <div class="containers">
        <div class="groupTitle">
          <p>{{$t('message.messageFrom')}}</p>
          <h2>{{$t('message.chairmanCeo')}}</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="groupImg">
              <img src="@/assets/picture-ceo.svg" alt="" />
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12">
            <div class="text-des">
              <p v-html="$t('message.desMessageFromChairmanCeoP')"></p>
            </div>
          </div>
        </div>
        <div class="groupText">
          <p v-html="$t('message.desMessageFromChairmanCeoP1')"></p>
          <p>{{$t('message.desMessageFromChairmanCeoP2')}}</p>
          <p>{{$t('message.desMessageFromChairmanCeoP3')}}</p>
          <p>{{$t('message.desMessageFromChairmanCeoP4')}}</p>
        </div>
      </div>
    </div>
    <div class="fixed-bottom">
      <IncFooter />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Headers/HeaderComponent.vue";
import IncFooter from "@/components/footer/IncFooter.vue";
export default {
  components: { HeaderComponent, IncFooter },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created(){
    this.scrollToTop();
  }
};
</script>

<style scoped>
.boarding {
  padding-top: 120px;
  padding-bottom: 50px;
}

/* .fixed-bottom{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
       
    } */
.groupTitle p {
  margin: 0px;
}
.groupTitle h2 {
  margin: 0px;
}
.row {
  padding-top: 25px;
}
.groupImg {
  height: 260px;
  background-color: burlywood;
}
.groupImg img {
  width: 100%;
  height: 100%;
  object-fit: "cover";
}
.text-des p {
  font-size: small;
}
.text-des span {
  font-weight: 600;
}
.groupText {
  padding-top: 30px;
}

@media (min-width: 1270px) and (max-width: 2060px) {
}
@media (width < 450px) {
  .text-des p {
    font-size: 14px;
  }
  .groupTitle {
    margin-top: -12px;
  }
  .groupText {
    padding-top: 10px;
  }
  .groupText p {
    font-size: small;
  }
}
</style>