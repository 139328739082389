<template>
    <div class="shedule">
        <div class="header-menu">
            <HeaderComponent />
        </div>
        <div class="chooseSeat">
           <choose-seat-back />
        </div>
        <div>
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import ChooseSeatBack from '@/components/seats/ChooseSeatBack.vue';
export default {
    components: { 
        HeaderComponent,
        IncFooter,
        ChooseSeatBack,
    },
    data() {
        return {}
    },
    methods: {},
    created() {}    
}
</script>

<style scoped> 
    
</style>