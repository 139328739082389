<template>
    <div>
        <div>
            <v-btn v-if="book" @click="goToScheduleAgain" block depressed style="border-radius: 4px;" color="#DE5D0A" height="39.02px" class="text-capitalize reposo-height">
                <span style="color: #FFF">{{$t('message.findNow')}}</span>
            </v-btn>
            <v-btn v-else @click="goToSchedule" block depressed style="border-radius: 4px;" color="#DE5D0A" height="39.02px" class="text-capitalize reposo-height">
                <span style="color: #FFF">{{$t('message.findNow')}}</span>
            </v-btn>
        </div>
        
        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="text-h5" text-center>{{$t('message.information')}}</v-card-title>
                    <v-card-text style="display: flex;">{{$t('message.pleaseSelectDeparture')}}</v-card-text>
                    <v-card-actions style="display: flex;justify-content: center;">
                        <v-btn block color="primary" @click="dialog = false">{{$t('message.ok')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog1" max-width="290">
                <v-card>
                    <v-card-title class="text-h5" text-center>{{$t('message.information')}}</v-card-title>
                    <v-card-text style="display: flex;">{{$t('message.pleaseSelectGoingTo')}}</v-card-text>
                    <v-card-actions style="display: flex;justify-content: center;">
                        <v-btn block color="primary" @click="dialog1 = false">{{$t('message.ok')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            dialog1: false,
            book: false,
        }
    },
    methods: {
        goToSchedule() {
            if(localStorage.getItem('departureFromName') == '' || localStorage.getItem('departureFromName') != null && localStorage.getItem('destinationToName') == '' || localStorage.getItem('destinationToName') != null && (localStorage.getItem('dateFrom') == '' || localStorage.getItem('dateFrom') != null)){
                if((localStorage.getItem('departureFromName') != '') && (localStorage.getItem('destinationToName') != '') && (localStorage.getItem('dateFrom') != '') &&  (localStorage.getItem('dateTo') == '' || localStorage.getItem('dateTo') == null)){
                    localStorage.getItem('departureFromName');
                    localStorage.getItem('destinationToName');
                    localStorage.getItem('dateFrom');
                    this.$router.push("/bus-list");
                }else{
                    localStorage.getItem('departureFromName');
                    localStorage.getItem('destinationToName');
                    localStorage.getItem('dateFrom');
                    localStorage.getItem('dateTo');
                    this.$router.push("/bus-list");
                }
            }else{
                if(localStorage.getItem('departureFromName') == null) {
                    this.dialog = true;
                }else{
                    this.dialog1 = true;
                }
            }
        },
        goToScheduleAgain() {
            if(localStorage.getItem('departureFromName') == '' || localStorage.getItem('departureFromName') != null && localStorage.getItem('destinationToName') == '' || localStorage.getItem('destinationToName') != null && (localStorage.getItem('dateFrom') == '' || localStorage.getItem('dateFrom') != null)){
                if((localStorage.getItem('departureFromName') != '') && (localStorage.getItem('destinationToName') != '') && (localStorage.getItem('dateFrom') != '') &&  (localStorage.getItem('dateTo') == '' || localStorage.getItem('dateTo') == null)){
                    localStorage.getItem('departureFromName');
                    localStorage.getItem('destinationToName');
                    localStorage.getItem('dateFrom');
                    this.$router.go();
                }else{
                    localStorage.getItem('departureFromName');
                    localStorage.getItem('destinationToName');
                    localStorage.getItem('dateFrom');
                    localStorage.getItem('dateTo');
                    this.$router.go();
                }
            }else{
                if(localStorage.getItem('departureFromName') == null) {
                    this.dialog = true;
                }else{
                    this.dialog1 = true;
                }
            }
        }
    },
    created() {
        if(localStorage.getItem('book-again') == 1) {
            this.book = true;
        }else {
            this.book = false;
        }
    }
}
</script>

<style>
    .v-dialog > .v-card > .v-card__title {
        justify-content: center;
    }
    .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 11px !important;
        justify-content: center;
        align-items: center;
    }
</style>