<template>
    <div class="bloger">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
            <HeaderComponent />
            <blogs-com />
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import BlogsCom from '@/components/blogs/blogsCom.vue';
export default {
    components: { 
       HeaderComponent,
       BlogsCom,
       IncFooter
    },
    data() {
        return {
            loading: true,
            reloadBlog: ""
        }
    },
    methods: {
       
    },
    created() {
        localStorage.setItem('reloadHome', "false");
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy','false');


        this.reloadBlog = localStorage.getItem('reloadBlog');
        if(this.reloadBlog != 'true') {
            localStorage.setItem('reloadBlog', 'true');
        }
        if(localStorage.getItem('reloadBlog') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
}
</script>

<style>
    .bloger {
        background-color: #F6F7F8;
        /* margin-top: 28px; */
    }
</style>