<template>
    <div class="choose">
        <div class="header-menu">
            <HeaderComponent />
        </div>
        <div class="chooseSeat">
            <choose-seat />
        </div>
        <div>
            <IncFooter />
        </div>
       
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import ChooseSeat from '@/components/seats/ChooseSeat.vue';
export default {
    components: { 
        HeaderComponent, 
        IncFooter,
        ChooseSeat
    },
    
    data() {
        return {}
    },
    methods: {},
    created() {}       
}
</script>

<style scoped> 
    
</style>