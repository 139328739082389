<template>
    <div class="rental-service">
        <!-- <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div> -->
        <div>
            <HeaderComponent />
            <rental-service />
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import RentalService from '@/components/RentalService/RentalService.vue';
export default {
    components: { 
       HeaderComponent,
       RentalService,
       IncFooter
    },
    data() {
        return {
            // loading: true,
            reloadRetal: ""
        }
    },
    methods: {
       
    },
    created() {
        localStorage.setItem('reloadHome', "false");
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy','false');
        
        // this.reloadRetal = localStorage.getItem('reloadRetal');
        // if(this.reloadRetal != 'true') {
        //     localStorage.setItem('reloadRetal', 'true');
        // }
        // if(localStorage.getItem('reloadRetal') == 'true') {
        //     setTimeout(() => {
        //         this.loading = false;
        //     }, 1000);
        // }
    },
}
</script>

<style>
     .rental-service{
        margin-top: 28px;
    }

    @media screen and (width < 950px){
        .rental-service {
            margin-top: -15px;
        }
   
        

    }
    @media screen and (width < 620px){
        .rental-service {
            margin-top: -15px;
        }
        
    }
    @media screen and (width < 500px){
        .rental-service {
            margin-top: -15px;
        }
    }
    @media(width < 450px) {
        .rental-service {
            margin-top: -15px;
        }  
    }

    @media screen and (width < 438px){
        .rental-service {
            margin-top: -15px;
        }


      
    }
</style>