import  createI18n from 'vue-i18n';
import en from "./en/index";
import kh from "./kh/index";
import ch from "./ch/index";
import Vue from 'vue';


Vue.use(createI18n);
const messages = {
  en: en,
  kh: kh,
  ch: ch
}
// Create Vuei18n instance with options
const i18n = new createI18n({
  locale: localStorage.getItem("lang") == null ? "en" : localStorage.getItem("lang"), // set default locale
  fallbackLocale: 'en',
  messages,
});

export default i18n;
