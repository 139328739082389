<template>
  <div>
    <div class="mission">
      <HeaderComponent />
      <div class="containers">
        <section>
          <div class="vet-mission">
            <h3>{{$t('message.vetVisionMission')}}</h3>
          </div>
          <div class="vet-mission-text">
            <p>{{$t('message.desVisionMision')}}</p>
          </div>
          <div class="our-slogan">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="our-slogan-icon">
                <img src="@/assets/our-slogan.svg" alt="" />
                <div>{{$t('message.ourSlogan')}}</div>
              </div>
              <ul>
                <li>{{$t('message.ourSloganList1')}}</li>
                <li>{{$t('message.ourSloganList2')}}</li>
                <li>{{$t('message.ourSloganList3')}}</li>
              </ul>
            </div>
          </div>
        </section>
        <div class="row" style="margin-top: 40px">
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 pa-t-b">
            <div class="cardVVM">
              <div style="height: 220px; width: 100%">
                <img
                  src="https://www.ictesolutions.com.au/media/1953/vision.jpg?anchor=center&mode=crop&width=1200&height=630&rnd=132396685480000000"
                />
              </div>
              <p>{{$t('message.vision')}}</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 pa-t-b">
            <div class="cardVVM">
              <div style="height: 220px; width: 100%">
                <img
                  src="https://centrendl.org/Posts_Images/the-purposedriven-mission-CentreNDL-Leadership-blog-Nigeria-Abuja-October-2020.jpg"
                />
              </div>
              <p>{{$t('message.mission')}}</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 pa-t-b">
            <div class="cardVVM">
              <div style="height: 220px; width: 100%">
                <img
                  src="https://images.prismic.io/staynimble-career-advice-hub/6f5312cd-248a-43ae-b469-a4c1c96d00ef_woman-writes-notebook-with-silver-pen-hand-hold-card-with-text-cash-flow.jpg?auto=compress%2Cformat&fit=max"
                />
              </div>
              <ul>
                <li>{{$t('message.coreValue1')}}</li>
                <li>{{$t('message.coreValue2')}}</li>
                <li>{{$t('message.coreValue3')}}</li>
                <li>{{$t('message.coreValue4')}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <IncFooter />
  </div>
</template>

<script>
import HeaderComponent from "@/components/Headers/HeaderComponent.vue";
import IncFooter from "@/components/footer/IncFooter.vue";
export default {
  components: { HeaderComponent, IncFooter },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created(){
    this.scrollToTop();
  }
};
</script>

<style scoped>
.mission {
  padding-bottom: 50px;
}
section {
  margin-top: 140px;
}
section .vet-mission {
  text-align: center;
}
section .vet-mission h3 {
  font-size: 36px;
  font-weight: 600;
  color: #de5d0a;
}
section .vet-mission span {
  color: #312783;
}
.vet-mission-text {
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
}
.vet-mission-text p {
  font-weight: 400px;
  font-size: 16px;
  color: #424245;
}
.our-slogan {
  margin-top: 40px;
}
.our-slogan-img {
  text-align: center;
  height: 215px;
}
.our-slogan-img img {
  border-radius: 12px;
  width: 360px;
  height: 100%;
}
.our-slogan-icon {
  display: flex;
  align-items: center;
}
.our-slogan-icon img {
  width: 45px;
  height: 45px;
}
.our-slogan-icon div {
  padding-left: 20px;
  font-size: 36px;
  font-weight: 600;
  color: #312783;
}
.our-slogan ul li {
  padding-top: 12px;
}
.cardVVM {
  cursor: pointer;
}
.cardVVM img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.cardVVM p {
  font-size: 16px;
  color: #1d1d1d;
}
.cardVVM ul li {
  font-size: 16px;
  color: #1d1d1d;
}
.cardVVM p:hover,
ul:hover li {
  color: #312783;
}

@media (width < 450px) {
  section {
    margin-top: 100px;
  }
  section .vet-mission h3 {
    font-size: 26px;
  }
  .vet-mission-text {
    padding-left: 60px;
    padding-right: 60px;
    text-align: left;
  }
  .vet-mission-text {
    padding-left: 14px;
    padding-right: 14px;
  }
}
</style>