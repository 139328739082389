import { render, staticRenderFns } from "./ComfirmOtp.vue?vue&type=template&id=22a4f9aa&scoped=true"
import script from "./ComfirmOtp.vue?vue&type=script&lang=js"
export * from "./ComfirmOtp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a4f9aa",
  null
  
)

export default component.exports