<template>
    
    <div class="login">
        <div>
            <HeaderComponent />
            <ResetNewPasswordComponent />
            <IncFooter />
        </div>
    </div>
    
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import ResetNewPasswordComponent from '@/components/users/ResetNewPasswordComponent.vue';
    export default {
        components: { 
            HeaderComponent,
            ResetNewPasswordComponent,
            IncFooter,
        },
        data(){
            return {
                // loading: true,
            }
        },
        method:{

        },
        created(){
            // this.loading = false
        }
    }
</script>
<style scoped>

</style>