<template>
    
    <div class="travel-package">
        <div>
            <HeaderComponent />
            <RegisterBuyPackageComponent />
            <IncFooter />
        </div>
    </div>
    
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import RegisterBuyPackageComponent from '@/components/TravelPackage/RegisterBuyPackageComponent.vue';
    export default {
        components: { 
            HeaderComponent,
            RegisterBuyPackageComponent,
            IncFooter,
        },
        data(){
            return {
                // loading: true,
            }
        },
        method:{

        },
        created(){
            // this.loading = false
        }
    }
</script>
<style scoped>

</style>