<template>
    <div>
        <div class="milestion">
            <HeaderComponent />
            <div class="bg-img">
                <section class="containers">
                    <div class="vet-mission pt-10">
                        <p>{{$t('message.vetMilestone')}}</p>
                    </div>
                    <div class="vet-mission-text">
                        <p>{{$t('message.desVetMilestone')}}</p>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2024')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2024List1')}}</li>
                                    <li>{{$t('message.vetMilestone2024List2')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2023')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2023List1')}}</li>
                                    <li>{{$t('message.vetMilestone2023List2')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2022')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2022List1')}}</li>
                                    <li>{{$t('message.vetMilestone2022List2')}}</li>
                                    <li>{{$t('message.vetMilestone2022List3')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">Y{{$t('message.vetMilestone2021')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2021List1')}}</li>
                                    <li>{{$t('message.vetMilestone2021List2')}}</li>
                                    <li>{{$t('message.vetMilestone2021List3')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2020')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2020List1')}}</li>
                                    <li>{{$t('message.vetMilestone2020List2')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2019')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2019List1')}}</li>
                                    <li>{{$t('message.vetMilestone2019List2')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2018')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2018List1')}}</li>
                                    <li>{{$t('message.vetMilestone2018List2')}}</li>
                                    <li>{{$t('message.vetMilestone2018List3')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2017')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2017List1')}}</li>
                                    <li>{{$t('message.vetMilestone2017List2')}}</li>
                                    <li>{{$t('message.vetMilestone2017List3')}}k</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2016')}}</p>
                                <ul>
                                    <li>T{{$t('message.vetMilestone2016List1')}}</li>
                                    <li>{{$t('message.vetMilestone2016List2')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2015')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2015List1')}}</li>
                                    <li>{{$t('message.vetMilestone2015List2')}}</li>
                                    <li>{{$t('message.vetMilestone2015List3')}}</li>
                                    <li>{{$t('message.vetMilestone2015List4')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2014')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2014List1')}}</li>
                                    <li>{{$t('message.vetMilestone2014List2')}}</li>
                                    <li>{{$t('message.vetMilestone2014List3')}}</li>
                                    <li>{{$t('message.vetMilestone2014List4')}}</li>
                                    <li>{{$t('message.vetMilestone2014List5')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2010')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2010List1')}}</li>
                                    <li>{{$t('message.vetMilestone2010List2')}}</li>
                                    <li>{{$t('message.vetMilestone2010List3')}}</li>
                                    <li>{{$t('message.vetMilestone2010List4')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2006')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2006List1')}}</li>
                                    <li>{{$t('message.vetMilestone2006List2')}}</li>
                                    <li>{{$t('message.vetMilestone2006List3')}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="all-year">
                                <img src="@/assets/handle.svg" alt="">
                                <p class="text-year">{{$t('message.vetMilestone2004')}}</p>
                                <ul>
                                    <li>{{$t('message.vetMilestone2004List1')}}</li>
                                    <li>{{$t('message.vetMilestone2004List2')}}</li>
                                    <li>{{$t('message.vetMilestone2004List3')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <IncFooter />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue'
import IncFooter from '@/components/footer/IncFooter.vue'
export default {
  components: { HeaderComponent, IncFooter },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created(){
    this.scrollToTop();
  }
}
</script>

<style scoped>
    .milestion {
        padding-top: 70px;
        padding-bottom: 50px;    
        
    }
    .bg-img {
        width: 100%;
        height: 1382px;
        border: 1px solid;
        background: url(@/assets/revamp-service-bg-updated.png);
    }
    section {
        padding-top: 15px;
    }
    section .vet-mission {
        text-align: center;
    }
    section .vet-mission p{
        font-size: 36px;
        font-weight: 600;
        color: #FFF;
    }
    .vet-mission-text {
        padding-left: 60px;
        padding-right: 60px;
        text-align: center;
    }
    .vet-mission-text p {
        font-weight: 400px;
        font-size: 16px;
        color: #FFF;
    }
    .all-year {
        padding-top: 15px;
        padding-left: 25px;
        padding-right: 25px;
        background: #ffffff;
        width: 100%;
        height: 262px;
        border-radius: 12px;
    }
    .all-year .text-year {
        margin-top: 20px;
        font-weight: 600;
        font-size: 16px;
        color: #1D1D1D;
    }
    @media(width < 1264px) {
        .bg-img {
            height: 2230px;
        }
    }
    @media(width < 613px) {
        .bg-img {
            height: 2270px;
        }
    }
    @media(width < 600px) {
        .bg-img {
            height: 4270px;
        }
    }
    @media(width < 600px) { 
        .bg-img {
            height: 4300px;
        }
    }
    @media(width < 438px) { 
        .bg-img {
            height: 4350px;
        }
    }
    @media(width < 365px) { 
        .bg-img {
            height: 4400px;
        }
    }
    @media(width < 327px) { 
        .bg-img {
            height: 4470px;
        }
    }
</style>