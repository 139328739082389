<template>
  <div class="bg-img">
    <div class="set-heights">
      <!-- <div class="carousel-container" @mouseover="showArrows = true" @mouseleave="showArrows = false">
                <ssr-carousel v-model="page"  class="set-height-width dss-none">
                    <slide :index='1'>
                      <img src="@/assets/cover.jpg" alt="">
                    </slide>
                    <slide :index='2'>
                      <img src="@/assets/cover.jpg" alt="">
                    </slide>
                    <slide :index='3'>
                      <img src="@/assets/cover.jpg" alt="">
                    </slide>
                </ssr-carousel>
                <div class="carousel-arrows" v-show="showArrows">
                    <div class="arrow left-arrow d-flex justify-center align-center" @click="prevPage"> <img src="@/assets/travel_package/left-chevron.svg" alt="" width="30px"> </div>
                    <div class="arrow right-arrow d-flex justify-center align-center" @click="nextPage"><img src="@/assets/travel_package/right-chevron.svg" alt="" width="30px"></div>
                </div>
            </div> -->
      <img src="@/assets/cover.jpg" alt="">
      <TicketBooking />
    </div>
  </div>
</template>
<script>
import TicketBooking from '../froms/TicketBooking.vue';
export default {
  components: { TicketBooking },
  date() { return {} },
}
</script>
<style scoped>
.bg-img {
  position: relative;
  height: 460px;
}

.set-heights {
  position: absolute;
  /* top: 0; */
  /* top: 110px; */
  top: 68px;
  height: 100%;
  width: 100%;
}

.set-heights img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.set-heights img {
  object-fit: cover;
}

@media (min-width: 1550px) and (max-width: 4049px) {
  .bg-img {
    height: 560px !important;
  }

  .set-heights img {
    object-fit: cover;
  }
}

@media(max-width: 960px) AND (min-width: 950px) {
  .set-heights {
    top: 110px !important;
  }
}

@media(max-width: 950px) {
  .set-heights {
    height: 100%;
    top: 64px;
  }
}

@media(width < 500px) {
  .bg-img {
    height: 400px;
  }

  .set-heights img {
    object-fit: cover;
  }

}
</style>