<template>
    <div class="restore">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
            <HeaderComponent />
            <resort-com />
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import ResortCom from '@/components/resort/ResortCom.vue';
export default {
    components: {
        HeaderComponent,
        ResortCom,
        IncFooter
        
    },
    data() {
        return {
            loading: true,
            reloadRestore: "",
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
    created() {
        localStorage.setItem('reloadHome', 'false');
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadBlog', 'false');
      
        this.scrollToTop();
        this.reloadRestore = localStorage.getItem('reloadRestore');
        if(this.reloadRestore != 'true') {
            localStorage.setItem('reloadRestore', 'true');
        }
        if(localStorage.getItem('reloadRestore') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }
}
</script>

<style>
    .restore{
        margin-top: 28px;
    }

    @media screen and (width < 950px){
        .restore {
            margin-top: -15px;
        }
   
        

    }
    @media screen and (width < 620px){
        .restore {
            margin-top: -15px;
        }
        
    }
    @media screen and (width < 500px){
        .restore {
            margin-top: -15px;
        }
    }
    @media(width < 450px) {
        .restore {
            margin-top: -15px;
        }
      
    
       
      
       
        
    }

    @media screen and (width < 438px){
        .restore {
            margin-top: -15px;
        }


      
    }
</style>