<template>
    <div class="gallers">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
            <HeaderComponent />
            <gallery-com />
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import GalleryCom from '@/components/galleries/galleryCom.vue';
export default {
    components: { 
        HeaderComponent,
        IncFooter,
        GalleryCom
    },
    data() {
        return {
            loading: true,
            narbarTop: {
                topOfPage: true
            },
            reloadGallers: ""
        }
    },
    methods: {
        
    },
    created() {
        localStorage.setItem('reloadHome', "false");
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy','false');
     
        this.reloadGallers = localStorage.getItem('reloadGallers');
        if(this.reloadGallers != 'true') {
            localStorage.setItem('reloadGallers', 'true');
        }
        if(localStorage.getItem('reloadGallers') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
}
</script>

<style scoped>

</style>