<template>
  <div>
    <HeaderComponent />
    <div class="blog">
      <div class="containers">
        <div class="row">
          <div class="contentWidth">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.crossBorderInformationOrocedure')}}</h2>
              <div class="blog-img">
                <img
                  v-if="img1"
                  src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
                  alt=""
                />
              </div>
              <div class="">
                <h4>{{$t('message.crossBorderTitle1')}}</h4>
                <p>{{$t('message.crossBorderText1')}}</p>
                <h4>{{$t('message.crossBorderTitle2')}}</h4>
                <p>{{$t('message.crossBorderText2')}}</p>
                <h4>{{$t('message.crossBorderTitle3')}}</h4>
                <p>{{$t('message.crossBorderText3')}}</p>
                <h4>{{$t('message.crossBorderTitle4')}}</h4>
                <p>{{$t('message.crossBorderText4')}}</p>
                <h4>{{$t('message.crossBorderTitle5')}}</h4>
                <p>{{$t('message.crossBorderText5')}}</p>
                <h4>{{$t('message.crossBorderTitle6')}}</h4>
                <p>{{$t('message.crossBorderText6')}}</p>
                <h4>{{$t('message.crossBorderTitle7')}}</h4>
                <p>{{$t('message.crossBorderText7')}}</p>
                <p>{{$t('message.crossBorderText71')}}</p>
                <h4>{{$t('message.crossBorderTitle8')}}</h4>
                <p>{{$t('message.crossBorderText8')}}</p>
                <h4>{{$t('message.crossBorderTitle9')}}</h4>
                <p>{{$t('message.crossBorderText9')}}</p>
                <h4>{{$t('message.crossBorderTitle10')}}</h4>
                <p>{{$t('message.crossBorderText10')}}</p>
                <h4>{{$t('message.crossBorderTitle11')}}</h4>
                <h4>{{$t('message.crossBorderTitle111')}}</h4>
                <p>{{$t('message.crossBorderText11')}}</p>
                <p>{{$t('message.crossBorderText111')}}</p>
                <h4>{{$t('message.crossBorderTitle12')}}</h4>
                <p>{{$t('message.crossBorderText12')}}</p>
                <p>{{$t('message.crossBorderText121')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <IncFooter />
  </div>
</template>

<script>
import HeaderComponent from "@/components/Headers/HeaderComponent.vue";
import IncFooter from "@/components/footer/IncFooter.vue";
export default {
  components: { HeaderComponent, IncFooter },
  data() {
    return {
      img1: false,
      img2: false,
      img3: false,
    };
  },
  methods: {},
  created() {
    if (localStorage.getItem("blog-img") == 1) {
      this.img1 = true;
      this.img2 = false;
      this.img3 = false;
    } else if (localStorage.getItem("blog-img") == 2) {
      this.img1 = false;
      this.img2 = true;
      this.img3 = false;
    } else {
      this.img1 = false;
      this.img2 = false;
      this.img3 = true;
    }
  },
};
</script>

<style scoped>
.blog {
  margin-top: 140px;
  padding-bottom: 50px;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contentWidth {
  width: 75%;
}
.blog-img {
  width: 100%;
  height: 380px;
  margin: 20px 0px;
}
.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-blog {
  width: 100%;
  height: 220px;
}
.img-blog img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
}
@media (width < 960px) {
  .blog-img {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (width < 450px) {
  .blog {
    margin-top: 100px;
  }
  .contentWidth {
    width: 100%;
  }
  .blog-img {
    width: 100%;
    height: 240px;
    margin: 20px 0px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
}
</style>