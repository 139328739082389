<template>
      <div class="forgotscreen">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="containers">
            <div class="row header-login">
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                
                </div>
                <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12" style="background: #FFFFFF; border-radius: 15px;">
                    <form>
                        <h2 class="text-center acount-header">New Password</h2>
                        <p class="login-paragraph text-center">Please enter your news password / comfirm password</p>
                        <div class="mb-4 mt-2 emailmargin" style="position: relative;">
                            <label class="inner-block"><span style="color: #FF0000;">*</span>  New Password</label>
                            <input v-if="showPassword" name="password" :class="{ 'is-invalid': isResetNewPassword && $v.password.$error }" type="text"  v-model="password" class="form-control form-control-lg form-input-login" placeholder="New password"/>
                            <input type="password" name="password" :class="{ 'is-invalid': isResetNewPassword && $v.password.$error }" autocomplete="off" v-else class="form-control form-control-lg form-input-login" placeholder="New password" v-model="password"/>
                            <button type="button" class="button" @click="toggleShowPassword"><span class="icon is-small is-right">
                                    <i class="fas" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                    </span>
                            </button>
                            <div v-if="isResetNewPassword && !$v.password.required" class="invalid-feedback">Password field is required</div>
                            <div v-if="!$v.password.minLength" class="invalid-feedback">Password should be at least 6 characters long</div>
                        </div>
                        <div class="mb-4 mt-2 emailmargin"  style="position: relative;">
                            <label class="inner-block"><span style="color: #FF0000;">*</span>  Confirm Password</label>
                            <input v-if="showComfirmPassword" :class="{ 'is-invalid': isResetNewPassword && $v.comfirmPassword.$error }" type="text" v-model="comfirmPassword" class="form-control form-control-lg form-input-login" placeholder="Confirm password"/>
                            <input type="password" :class="{ 'is-invalid': isResetNewPassword && $v.comfirmPassword.$error }" autocomplete="off" v-else class="form-control form-control-lg form-input-login" placeholder="Confirm password" v-model="comfirmPassword"/>
                            <button type="button" class="button" @click="toggleShowComfirmPasswordPassword"><span class="icon is-small is-right">
                                    <i class="fas" :class="{ 'fa-eye': showComfirmPassword, 'fa-eye-slash': !showComfirmPassword }"></i>
                                    </span>
                            </button>
                            <div v-if="isResetNewPassword && $v.comfirmPassword.$error" class="invalid-feedback">
                                <span v-if="!$v.comfirmPassword.required">Confirm Password field is required</span>
                                <span v-else-if="!$v.comfirmPassword.sameAsPassword">Passwords should be matched</span>
                            </div>
                        </div>
                        <div class="mb-2 mt-2 emailmargin">
                            <v-btn @click="addResetNewPassword" :disabled="disabled" style="border-radius: 4px; width:  91%; outline: none;" color="#DE5D0A" height="50px"  class="text-capitalize btn-width reposo-height">
                                <span style="color: #FFF; font-size: 22px !important;"> {{ titleresetnew }} </span>
                            </v-btn>
                            
                        </div>
                    </form>
                    
                </div>
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                    
            </div>
        </div>
        </div>
        </div>
        
    </div>
</template>
<script>
import {
      required,
      sameAs,
      minLength
  } from "vuelidate/lib/validators";
  import axios from "axios";
    export default{
        data(){
            return{
                showPassword: false,
                password: '',
                showComfirmPassword: false,
                comfirmPassword:'',
                isResetNewPassword:false,
                titleresetnew:'Continue',
                disabled:false,
            }
        },
        validations:{
            password: {required, minLength: minLength(6)},
            comfirmPassword: {
                  required,
                  sameAsPassword: sameAs('password')
              },
             
        },
        computed: {
            buttonLabel() {
                return (this.showPassword) ? "Hide" : "Show";
            }
        },
        methods:{
            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
            toggleShowComfirmPasswordPassword() {
                this.showComfirmPassword = !this.showComfirmPassword;
            },
            async addResetNewPassword(){
                // console.log(this.password);
                this.isResetNewPassword = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }else{
                        this.disabled = true;
                        this.titleresetnew = "Loading";
                        const data = {
                            'newPassword'           : this.password,
                            'token'                 : localStorage.getItem('tokenUser'),
                        }
                        const url = this.$url + `user-register/new-password`;
                        let config = {
                            method: "POST",
                            headers: {
                                ...this.$header,
                                "Content-Type": "multipart/form-data"
                            },
                            data: data,
                            url,
                        };
                        axios(config).then((response) => {
                            if (response.status == 200) {
                                this.disabled = false;
                                this.titleresetnew = "Continue";
                                if (response.data.header.result == true && response.data.header.statusCode == 200) {
                                    if(response.data.body.status == true) {
                                        
                                        setTimeout(() => {
                                            this.$router.push('/login');
                                        }, 1000);
                                    }else{
                                       alert(response.data.body.message);
                                    }
                                }else {
                                    alert("Please Input Information");
                                }
                            } 
                        }).catch((error) => {
                            console.log(error);
                        });
                }
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
        },
        created(){
            // localStorage.setItem("handleBrowser", 1);
            this.scrollToTop();
        }
    }
</script>
<style scoped>
    .forgotscreen {
        padding-top: 55px;
        /* padding-bottom: 50px; */
        margin-top: 28px;
      
    }
    .button{
        margin-left: -28px;
        color: #B7B5B5;
    }
    .invalid-feedback{
        color: #FF0000;
    }
    .is-invalid{
        border: 1px solid #FF0000 !important;
    }
    .acount-header{
        margin-top: 15px;
        
    }
    .emailmargin{
        margin-left: 40px;
    }
    .header-login{
        padding-top: 35px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .login-paragraph{
        color: #6A6969;
        font-size: 13px;
    }
    label {
        font-weight: bold;
    }
    .inner-block{
        display: block;
        width: 92%;
        margin-bottom: 4px;
    }
    .form-input-login{
        /* width: 100%; */
        width: 91%;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 45px;
        outline: none;
        padding-left: 12px;
    }
    .forgetPasswordform{
        width: 91%;
        text-align: right;
    }

    .btn-width{
        width: 91%;
        border-radius: 4px;
        background-color: #DE5D0A;
        /* z-index: 10000; */
    }
  
    @media(min-width: 1740px) {
        
    }
    
    @media(width < 1024px) {
    
    }
    @media screen and (width < 950px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        

    }
    @media screen and (width < 620px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media screen and (width < 500px){
        /* .forgotscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
    @media(width < 450px) {
        /* .forgotscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        
    }

    @media screen and (width < 438px){
        /* .forgotscreen {
            margin-top: -20px;
        } */


        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
    }
</style>