<template>
  <v-app>
    <!-- <div class="custom-row" style="position: fixed;z-index: 10;bottom: 47px;right: 3%;">
      <div v-if="hide" class="hide-show">
        <div class="telegrm">
          <a href="https://t.me/virakbunthamexpress" target="blank">
            <img src="@/assets/amenity/telegram.svg" style="width: 45px;border-radius: 8px;" alt="">
          </a>
        </div>
        <div class="comment" style="margin-top: 5px">
          <img src="@/assets/amenity/messenger.svg" style="width: 45px;border-radius: 8px;" alt="">
        </div>
      </div>
      <div>
        <div v-if="hide" @click="hideIcon" class="xmark" style="margin-top: 5px"><span class="fa-solid fa-xmark"></span></div>
        <div v-else @click="hideIcon" class="xmark" style="margin-top: 5px"><span class="fa-solid fa-plus"></span></div>
      </div>
    </div> -->
    <div class="custom-row gotopbtn" :class="{'topScroll': !narbarTop.topOfPage}">
      <div class="chevron" @click="toTop" style="margin-top: 5px"><span class="fa fa-chevron-up"></span></div>
    </div>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      hide: true,
      narbarTop: {
        topOfPage: true
      },
    }
  },
  methods: {
    hideIcon() {
      this.hide = !this.hide;
    },  
    handleScroll() {
      if(window.pageYOffset>600){
        if(this.narbarTop.topOfPage) this.narbarTop.topOfPage = false
      } else {
        if(!this.narbarTop.topOfPage) this.narbarTop.topOfPage = true
      }
    },
    toTop() {
      window.scrollTo({
        top:0,
        behavior: "smooth"
      });
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  created() {
    this.scrollToTop();
    localStorage.getItem('selectNational');
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>
<style>
  body {
    background: #e7e9ed;
    color: #535b61;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 22px;
  }
  .mdi-chevron-down::before {
    content: "\F0140";
    color: #FFF !important;
  }
  .mdi-chevron-right::before {
    content: "\F0142";
    color: #000 !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 0px 30px !important;
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }
  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #fff !important;
  }
  .v-input__slot {
    align-items: center;
    display: flex;
    margin-bottom: -5px !important;
    min-height: inherit;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: height, min-height;
    width: 100%;
  }
  .v-list-item--dense .v-list-item__content[data-v-9613ffb8], .v-list--dense .v-list-item .v-list-item__content[data-v-9613ffb8] {
    transition: all 0.3s ease-in-out;
    padding: 13px !important;
  }
  .v-list-item--dense .v-list-item__content[data-v-9613ffb8], .v-list--dense .v-list-item .v-list-item__content[data-v-9613ffb8]:hover {
    background: #1867c0;
    color: #FFF;
    cursor: pointer;
    border-radius: 2px;
  }
  .v-expansion-panel::before {
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: none !important;
  }
  .v-label {
    font-size: 14px;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .v-application--is-ltr .v-expansion-panel-header__icon {
    margin-left: auto;
    display: none;
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: inherit;
    color: rgba(0, 0, 0, 0.87);
  }
  .gotopbtn {
    display: none;
  }
  .custom-row {
    width: 45px;
  }
  .custom-row .telegrm, .comment, .xmark, .chevron {
    cursor: pointer;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin: auto;
  }
  .custom-row .telegrm, .comment{
    border-radius: 8px;
    font-size: 20px;
    background: #230080; 
  }
  .custom-row .fa-telegram , .fa-comment {
    color: #fff;
  }
  .custom-row .xmark {
    border-radius: 8px;
    font-size: 20px;
    background: #D9D9D9;
  }
  .custom-row .chevron {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 20px;
    background: #D9D9D9;
  }
  .custom-row .fa-xmark, .fa-chevron-up{
    color: #000000;
  }
  .topScroll {
    display: block;
    position: fixed;
    bottom: -6px;
    right: 3%;
    z-index: 10;
  }
</style>