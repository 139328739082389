<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
        <HeaderComponent />
        <div class="payment" style="background-color: #f2f2f2;">
            <div class="containers" style="margin-top: 35px;">
                <div class="cancel-booking-orderId" style="border-bottom: 1px solid #C6C6C6; padding-bottom: 16px;">
                    <div class="cancel-booking" @click="cancelBooking">
                        {{ $t('message.cancelBooking') }}
                    </div>
                    <div class="orderId">
                       <!-- <div class="order-id-transaction">
                           <span> {{ $t('message.orderId') }}: {{ transactionId }}</span>
                       </div>
                       <div class="expired-transaction-timeout">
                           <span> {{ $t('message.completeYourPaymentbeforetimeout') }}: <span style="color: #DE5D0A;">{{ timesetTransa }}</span> </span>
                       </div> -->
                    </div>
                </div>
                <div class="row mt-8" style="position: relative;">
                    <div v-html="paymentFormPost" style="display: none;"></div>
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="row" style="background: #FFFFFF !important; border-radius: 8px; opacity: 1; z-index: 100;">
                            <div class="col-lg-12 col-md-12 col-sm-12" style="padding-bottom: 16px;">
                                <div class="left">
                                <div class="trip-sum">Trip Summary</div>
                                <div class="all-info">{{ $t('message.billingDetail') }}</div>
                                <div class="all-info d-flex"> 
                                    <div style="width: 35%;">
                                        {{ $t('message.orderId') }}:
                                    </div>  
                                    <div style="width: 65%;">
                                        {{ transactionId }}
                                    </div>
                                </div>
                                <div class="all-info d-flex">
                                    <div style="width: 35%;">
                                        {{ $t('message.email') }}:
                                    </div>
                                    <div style="width: 65%;">
                                         {{ getEmail() }}
                                    </div>
                                    
                                </div>
                                <div>
                                    <div class="all-info d-flex" v-if="tleFalse">
                                        <div style="width: 35%;">{{ $t('message.tel') }}:</div> 
                                        <div style="width: 65%;">{{ phone }}</div>
                                    </div>
                                    <div class="all-info" v-else></div>
                                </div>
                                
                            
                                <div >
                                    <div class="all-info" style="border: 0.4px solid #C6C6C6;"></div>
                                    <!-- <div class="all-info"><img style="width: 100%;" src="@/assets/Line_11.png" alt=""></div> -->
                                    <div class="all-info">{{ getDestinationFrom() }} - {{ getdestinationToName() }}</div>
                                    <div class="all-info">{{ getDateFrom() }} ({{ convertTimes(getDeparture()) }})</div>
                                    <div class="all-info">{{ $t('message.numberOfPassenger') }} : {{ getSeatNum() }}</div>
                                    <div class="all-info">{{ $t('message.seatNumber') }} : {{ getSeatLabel() }}</div>
                                </div>
                                <div v-if="returnTrip">
                                    <!-- <div class="all-info" style="border: 0.4px dotted #C6C6C6;"></div> -->
                                    <div class="all-info"><img style="width: 100%;" src="@/assets/Line112.png" alt=""></div>
                                    <div class="all-info">{{ getdestinationToName() }} - {{ getDestinationFrom() }}</div>
                                    <div class="all-info">{{ getDateTo() }} ({{ convertTimes(getDepartureBack()) }})</div>
                                    <div class="all-info">{{ $t('message.numberOfPassenger') }} : {{ getSeatNumBack() }}</div>
                                    <div class="all-info">{{ $t('message.seatNumber') }} : {{ getSeatLabelBack() }}</div>
                                </div>
                                <!-- <div class="all-info" v-if="getTotalLuckeydraw() != 0">Lucky Ticket: $   {{ getTotalLuckeydraw() }}</div> -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-6 col-md-5 col-sm-12">
                        <div class="passDetails">
                            <!-- <div class="number">1</div> -->
                            <div class="title-pass" >{{ $t('message.paymentMethod') }}</div>
                        </div>
                        <div style="font-size: 16px;font-weight: 500;margin-top: 20px;">{{ $t('message.choosePaymentMethod') }}</div>
                        <!-- <form action=""> -->
                            <div class="select-radio">
                                <v-radio-group v-model="radioGroup">
                                    <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/KHQR.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title" style="line-height: 20px;">
                                                {{ $t('message.abakhqr') }}
                                            </div>
                                            <div class="txt">
                                                {{ $t('message.desabakhqr') }}
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="5" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div>
                                    <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/generic.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title">
                                                {{ $t('message.creditDebitCard') }}
                                            </div>
                                            <div class="txt">
                                                <img src="@/assets/4_Cards.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="6" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div>
                                    <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/wing_back_pay.jpg" style="width: 40px;height: 30px;border-radius: 4px;" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"  style="line-height: 20px;">
                                                {{ $t("message.wingBank") }}
                                            </div>
                                            <div class="txt">
                                                {{ $t('message.desWingBank') }}
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A"  :value="4" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div>
                                </v-radio-group>
                            </div>
                   
                        <div class="all-payment">
                            <div class="type-payment">{{ $t('message.paymentMethod') }}:</div>
                            <div class="price-payment">
                                <span class="all-style" v-if="radioGroup == 4">{{ $t('message.wingBank') }}</span>
                                <span class="all-style" v-if="radioGroup == 5">{{ $t('message.abakhqr') }}</span>
                                <span class="all-style" v-if="radioGroup == 6">{{ $t('message.creditDebitCard') }}</span>
                              
                            </div>
                        </div>
                        <div class="container-total">
                            <div v-if="returnTrip">
                                <div class="subtotal">
                                    <div class="text-total">{{ $t('message.grandTotal') }}:</div>
                                    <div class="price-total">$ {{formatPrice(grandTotalRe())}}</div>
                                </div>
                                <div class="subtotal">
                                    <div v-if="showDiscountback" style="width: 100%;display: flex;">
                                        <div class="text-total">{{ $t('message.discount') }} ({{ disPercent ? disPercent :'0' }}%):</div>
                                        <div class="price-total">$ {{formatPrice(totalDiscosunt())}}</div>
                                    </div>
                                    <!-- <div v-else style="width: 100%;display: flex;">
                                        <div class="text-total">Discount (0%):</div>
                                        <div class="price-total">$ 0.00</div>
                                    </div> -->
                                </div>
                                <div class="subtotal" v-if="getTotalLuckeydraw() != 0">
                                    <div class="text-total" >{{ $t('message.luckyTicket') }}:</div>
                                    <div class="price-total" >$ {{formatPrice(getTotalLuckeydraw())}}</div>
                                </div>
                              
                                <div class="subtotal">
                                    <div class="text-total" style="font-size: 20px;">{{ $t('message.total') }}:</div>
                                    <div class="price-total" style="font-size: 20px;">$ {{formatPrice(totalRetureTirp())}}</div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="subtotal">
                                    <div class="text-total">{{ $t('message.grandTotal') }}:</div>
                                    <div class="price-total">$ {{formatPrice(grandTotal())}}</div>
                                </div>
                                <div class="subtotal">
                                    <div v-if="showDiscount" style="width: 100%;display: flex;">
                                        <div class="text-total">{{ $t('message.discount') }} ({{ disPercent ? disPercent :'0' }}%):</div>
                                        <div class="price-total">$ {{formatPrice(disConut())}}</div>
                                    </div>
                                    <!-- <div v-else style="width: 100%;display: flex;">
                                        <div class="text-total">Discount (0%):</div>
                                        <div class="price-total">$ 0.00</div>
                                    </div> -->
                                </div>
                                <div class="subtotal" v-if="getTotalLuckeydraw() != 0">
                                    <div class="text-total" >{{ $t('message.luckyTicket') }}:</div>
                                    <div class="price-total" >$ {{formatPrice(getTotalLuckeydraw())}}</div>
                                </div>
                                <div class="subtotal">
                                    <div class="text-total" style="font-size: 20px;">{{ $t('message.total') }}:</div>
                                    <div class="price-total" style="font-size: 20px;">$ {{formatPrice(getTotal())}}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span>{{ $t('message.desTermCoditionPrivacyPolicy') }} <a href="/term" target="_blank" style="color: #312783;">{{ $t('message.termCondition') }}</a>  {{ $t('message.desTermCoditionPrivacyPolicyAnd') }} <a href="/policy" target="_blank" style="color: #312783;">{{ $t('message.privacyPolicy') }}</a></span>
                        </div>
                        <button :disabled="disable" class="book-pay-now" @click="getProcessPayment">
                            {{ $t(titleButton) }}
                        </button>   
                    </div>
                    
                    <transition name="fade">
                        <div v-if="showPaymentFrame" class="payment-frame">
                            <div class="set-wi-he">
                                <i class="fa-solid fa-xmark" @click="showPaymentFrameFal" style="cursor: pointer;position: absolute;top: 31px;right: 4%;font-size: 20px;"></i>
                                <iframe style="border-radius: 20px;" :src="paymentUrl" frameborder="0" width="100%" height="100%"></iframe>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <IncFooter />
    </div>
</template>
<script src="https://checkout.payway.com.kh/plugins/checkout2-0.js"></script>// production
<!-- <script src="https://checkout.payway.com.kh/plugins/checkout2-0-dev.js"></script> // qa -->
<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import axios from 'axios';
import qs from 'qs';
import { loadScript } from "vue-plugin-load-script";
export default {
    components: { HeaderComponent, IncFooter },
    data() {
        return {
            loading: false,
            tleFalse: false,
            phone: "",
            info: [],
            radioGroup: 5,
            isActive: false,
            returnTrip: false,
            showDiscount: false,
            showDiscountback: false,
            discount: 5,
            showPaymentFrame: false,
            titleButton:"message.payNow",
            paymentUrl: "",
            urlABA: 'https://checkout.payway.com.kh/plugins/checkout2-0.js', // production
            // urlABA: 'https://checkout.payway.com.kh/plugins/checkout2-0-dev.js', // qa
            disable:false,
            transactionId: this.$route.query.transactionId,
            test: [],
            paymentFormPost: "",
            seconds: 360,
            timesetTransa: '',

            disPercent: localStorage.getItem('disPercent'),
            disPercentBack: localStorage.getItem('disPercentBack'),
        }
    },
    methods: {
        convertTimes(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        },
        startTimer() {
            this.timer = setInterval(() => {
                if (this.seconds > 1) {
                    this.seconds--;
                    this.timesetTransa =  this.formatTime(this.seconds);
                } else {
                    clearInterval(this.timer);
                // Optionally, you can perform an action when the countdown reaches zero
                }
            }, 1000);
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
        },
        pad(value) {
            return value < 10 ? `0${value}` : value;
        },
        getCheck() {
            if(localStorage.getItem('dateTo') != null){
                this.returnTrip = true;
            }else{
                this.returnTrip = false;
            }
        }, 
        getTotalLuckeydraw() { return localStorage.getItem('totalluckydraw'); },
        getDestinationFrom() { return localStorage.getItem('departureFromName'); },
        getdestinationToName() { return localStorage.getItem('destinationToName');},
        getDateFrom() { return localStorage.getItem('dateFrom');},
        getDeparture() { return localStorage.getItem('departure');},
        getDateTo() { return localStorage.getItem('dateTo');},
        getDepartureBack() { return localStorage.getItem('departureback');},    
        getTotal() { 
            if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null){
                this.showDiscount = true;
                return parseFloat(localStorage.getItem('resultTotal')) - this.disConut() + parseFloat(localStorage.getItem('totalluckydraw')) ;
            }else{
                this.showDiscount = false;
                return parseFloat(localStorage.getItem('resultTotal')) +  parseFloat(localStorage.getItem('totalluckydraw')) ;
            }
        },   
        grandTotal() {
            return localStorage.getItem('resultTotal');
        },
        disConut() {
            let totals = parseFloat(localStorage.getItem('discount')) * parseInt(this.getSeatNum());
            return totals;
        },
       
        totalDiscosunt() {
            if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                let totalDiscount = parseFloat(this.disConut()) + parseFloat(this.disConutBack());
                // let calu = totalDiscount * this.discount/100;
                return totalDiscount;
            }else if((localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                return this.disConut();
            }else if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null)){
                return this.disConutBack();
            }else{
                return "0.00";
            }
        },
        getFullName() { return localStorage.getItem('fullname') },
        getEmail() { return localStorage.getItem('email') },
        getPhone() { 
            if(localStorage.getItem("phone") != null && localStorage.getItem("phone") != "") {
                this.tleFalse = true;
                this.phone = localStorage.getItem('phone');
            }else{
                this.tleFalse = false;
            }
            
        },

        getSeatNum() {
            if(localStorage.getItem('seat') != '') {
                let seatNum = localStorage.getItem('seat');
                let seatNumArray = seatNum.split(",");
                return this.seatNum = seatNumArray.length;
            }
        },
        getSeatLabel() {
            if(localStorage.getItem('seatlabel') != '') {
                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if(i > 0){
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                return this.seatLabel = lblSeat;
            }
        },
        getSeatNumBack() {
            if(localStorage.getItem('seatback') != '') {
                let seatNumBack = localStorage.getItem('seatback');
                let seatNumBackArray = seatNumBack.split(",");
                return this.seatNumBack = seatNumBackArray.length;
            }
        },
        getSeatLabelBack() {
            if(localStorage.getItem('seatlabelback') != '') {
                let size = localStorage.getItem('seatlabelback');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if(i > 0){
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                return this.seatLabel = lblSeat;
            }
        },

        // Return Trip (one way)
        grandTotalRe() {
            return parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
        },
        disConutBack() {
            let disConutBack = parseFloat(localStorage.getItem('discountBack')) * parseInt(this.getSeatNumBack());
            return disConutBack;
        },
        totalRetureTirp() {
            if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                this.showDiscountback = true;
                let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.totalDiscosunt() +  parseFloat(localStorage.getItem('totalluckydraw')) ;
                return totals;
            }else if((localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                this.showDiscountback = true;
                let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.disConut() + parseFloat(localStorage.getItem('totalluckydraw')) ;
                return totals;
            }else if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null)){
                this.showDiscountback = true;
                let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.disConutBack() + parseFloat(localStorage.getItem('totalluckydraw'));
                return totals;
            }else{
                this.showDiscountback = false;
                return parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) ;
            }
        },
        // cut (,)
        formatPrice(value) { let val = (value/1).toFixed(2); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");},
        showPaymentFrameFal() {
            if (window.confirm("Are you sure you want to close?")) {
                this.showPaymentFrame = false;
                this.$router.go();
            } else {
                // User clicked Cancel
                // Perform any other action or do nothing
            }
        },
        

        async getProcessPayment() {
            if(this.radioGroup == 0) {
                alert("Please select payment type before click button pay now.");
            }else{
                this.titleButton="Loading";
                this.disable = true;
                const data = { 
                    'code': this.$route.query.transactionId,
                    'paymentMethodId': parseInt(this.radioGroup)
                }
                const url = this.$url + `booking/processPayment`;

                let config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(data),
                    url
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        if(response.data.header.result == true && response.data.header.statusCode == 200) {  
                            this.titleButton="message.payNow";
                            this.disable = false;
                            this.info = response.data.body;
                              if(this.info.status == 1){ // Ture
                                // let paymentToken = this.info.token;
                                if(this.radioGroup == 4){
                                    if(response.data.body.request != "") {
                                        window.location.href = response.data.body.request;
                                    }else{
                                        alert("Invaild Payment Method");
                                    }
                                } else {
                                    this.paymentFormPost = response.data.body.request;
                                    loadScript(this.urlABA)
                                    .then(() => {
                                        AbaPayway.checkout();
                                    })
                                    .catch(() => {
                                        console.log("error process payment aba");
                                    });
                                    
                                    // this.showPaymentFrame = true;
                                    // if(this.showPaymentFrame == true) {
                                        // this.paymentUrl = "https://vetticket.utlog.net/payments/busWebsitePaymentProcess/"+this.$route.query.transactionId+"/"+paymentToken;
                                        // this.checkAbaTransaction();
                                        //this.getAbaRequestForm(this.$route.query.transactionId, paymentToken);
                                    // }else{
                                    //     this.showPaymentFrame = false;
                                    // }
                                }
                            } else { // Session Expired
                                alert("Session Expired");
                                this.$router.push("/");
                            }
                        } else { // Error
                            alert("Sorry, the payment process failed. Please try it again.");
                            // this.$router.push("/");
                        }
                    }else {
                        console.log('Not Status 200');
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }, 
       
        // async checkAbaTransaction() {
        //     const data = { 
        //         'transactionId': this.$route.query.transactionId,
        //     }
        //     const url = this.$url + `booking/checkAbaPaymentStatus`;
        //     let config = {
        //         method: "POST",
        //         headers: this.$header,
        //         data: qs.stringify(data),
        //         url
        //     };
        //     axios(config).then((response) => {
        //         if (response.status == 200) {
        //             if(response.data.header.result == true && response.data.header.statusCode == 200) {  
        //                 this.info = response.data.body;
        //                 if(this.info.status == 1){ // Ture
        //                     this.paymentComplete();
        //                 } else { // Session Expired
        //                     setTimeout(() => {
        //                         this.checkAbaTransaction();
        //                     }, 5000);
        //                 }
        //             }
        //         }
        //     }).catch((error) => {
        //         console.log(error);
        //         setTimeout(() => {
        //             this.checkAbaTransaction();
        //         }, 5000);
        //     });
        // },
        async paymentComplete() {
            const data = {
                'transactionId': this.$route.query.transactionId
            }
            const url = this.$url + `booking/complete`;
            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url               
            };
            this.clearStorage();
            this.loading  = true;
            axios(config).then((response) => {
                if (response.status == 200) {
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {  
                        this.info = response.data.body;
                        if(this.info.status == 1){ // Complete
                            // this.showPaymentFrame = false;
                            this.$router.push({
                                path: "/payment-success",
                                query: {
                                    transactionId: this.$route.query.transactionId
                                }
                            });
                        } else { // not complete
                            this.loading = false;
                            this.showPaymentFrame = false;
                            alert("Payment Not Success");
                        }
                    } else { // Error
                        this.loading = false;
                        this.showPaymentFrame = false;
                        if (window.confirm("Please try it again.")) {
                            this.cancelBooking();
                        }else{
                            // User clicked Cancel
                            // Perform any other action or do nothing
                        }
                    }
                }
            }).catch((error) => {console.log(error);});
        },
        async cancelBooking() {
            if (window.confirm("Do you want to cancel booking?")) {
                const data = {
                    'transactionId': this.$route.query.transactionId,
                }
                const url = this.$url + `booking/cancel`;
                let config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(data),
                    url
                };
                this.loading = true;
                axios(config).then((response) => {
                    this.loading = false;
                    if (response.status == 200) {
                        this.$router.push("/");
                    }
                }).catch((error) => {console.log(error);});
            } else {
                // User clicked Cancel
                // Perform any other action or do nothing
            }
        },
        clearStorage() {
            localStorage.removeItem("departureFromName");
            localStorage.removeItem("departureFromId");
            localStorage.removeItem("destinationToName");
            localStorage.removeItem("destinationToId");
            localStorage.removeItem("dateFrom");
            localStorage.removeItem("dateTo");

            localStorage.removeItem("price");
            localStorage.removeItem("departure");
            localStorage.removeItem("arrival");
            localStorage.removeItem("duration");
            localStorage.removeItem("journeyid");
            localStorage.removeItem("priceOriginal");

            localStorage.removeItem("priceback");
            localStorage.removeItem("departureback");
            localStorage.removeItem("durationback");
            localStorage.removeItem("arrivalback");
            localStorage.removeItem("journeyidback");
            localStorage.removeItem("priceOriginalback");

            localStorage.removeItem("resultTotal");
            localStorage.removeItem("resultTotalBack");
            localStorage.removeItem("totalluckydraw");

            localStorage.removeItem("seat");
            localStorage.removeItem("seatlabel");
            localStorage.removeItem("seatback");
            localStorage.removeItem("seatlabelback");

            localStorage.removeItem("trips");
            localStorage.removeItem("busTittle");
            localStorage.removeItem('book-again');
            localStorage.removeItem('blog-img');

            localStorage.removeItem("email");
            localStorage.removeItem("phone");
    
         
            localStorage.removeItem('boardingPointLats');
            localStorage.removeItem('boardingPointLongs');
            localStorage.removeItem('dropOffPointLats');
            localStorage.removeItem('dropOffPointLongs');    

            localStorage.removeItem('discount');
            localStorage.removeItem('disPercent');
            localStorage.removeItem('discountBack');
            localStorage.removeItem('disPercentBack'); 
            
            // clear confirm booking
            localStorage.removeItem("boardingPointId");
            localStorage.removeItem("dropOffPointId");
            localStorage.removeItem("boardingPointIdBack");
            localStorage.removeItem("dropOffPointIdBack");
            localStorage.removeItem("gender");

            localStorage.removeItem("selection1");
            localStorage.removeItem("selection2");
            localStorage.removeItem("selection3");
            localStorage.removeItem("selection4");
        }
    },
    created() {
        // this.startTimer();
        this.getPhone();
        this.getCheck();
    },
    mounted() {
        window.onpopstate = () => {
           this.cancelBooking();
        };
        loadScript(this.urlABA);

    },
    beforeUnmount() {
        window.onpopstate = null;
    },
}
</script>

<style scoped>
    .by-follow a:hover {
        color: #333;
    }
    .payment {
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .cancel-booking-orderId {
        display: flex;
        align-items: center;
    }
    .cancel-booking-orderId .cancel-booking {
        width: 50%;
        cursor: pointer;
        font-weight: 600;
    }
    .cancel-booking-orderId .cancel-booking:hover {
        color: #4248e6;
    }
    .cancel-booking-orderId .orderId {
        /* text-align: end; */
        width: 50%;
    }
    .cancel-booking-orderId .orderId .order-id-transaction{
        padding-bottom: 15px;

    }
    .cancel-booking-orderId .orderId .expired-transaction-timeout{
        padding-bottom: 15px;
    }
    .servicefee{
        position: absolute;
        left: 50%;
        top: 30%;
    }

    /* right */
    .passDetails {
        display: flex;
        align-items: center;
    }
    .passDetails .number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #312783;
        background: #312783;
    }
    .passDetails .title-pass {
        /* margin-left: 15px; */
        font-weight: 600;
        font-size: 20px;
        color: #0c2f54;
    }
    .select-radio .card-select-payment {
        position: relative;
        background: #EEEEF3;
        margin-top: 20px;
        padding: 17px;
        border-radius: 6px;
        display: flex;
        align-items: center;
    }
    .select-radio .card-select-payment .text .title {
        font-weight: 600;
        font-size: 12px !important;
        color: #424245;
    }
    .dotted {
        padding-top: 10px;
    }
    .select-radio .card-select-payment .text .txt {
        font-weight: 400;
        font-size: 12px !important;
        color: #6E6E73;
    }
    .select-radio .card-select-payment .img-card {
        width: 10%;
    }
    .select-radio .card-select-payment .text {
        width: 80%;
    }
    /* .select-radio .card-select-payment .radios {
        display: flex;
        justify-content: end;
        width: 10%;
    } */
    @media(width < 960px) {
        .select-radio {
            padding-left: 0px;
        }
        .select-radio .card-select-payment .text { 
            padding-left: 20px;
        }
    }
    @media (width < 457px) {
        .select-radio .card-select-payment {
            padding: 8px;
        }
    }
    @media(width < 435px) {
        .passDetails .title-pass {
            font-size: 13px;
        }
        .type-payment , .text-total{
            width: 40% !important;
        }
        .price-payment , .price-total{
            width: 60% !important;
        }
    }

    /* Left */
    .left {
        padding-right: 20px;
    }
    .left .trip-sum {
        color: #0c2f54;
        font-weight: 600;
        font-size: 20px;
        margin-top: 5px;
    }
    .left .all-info {
        color: #1D1D1D;
        margin-top: 13px;
        font-weight: 545;
        font-size: 16px;
    }
    .all-payment {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .all-payment .type-payment{
        width: 30%;
    }
    .all-payment .price-payment {
        width: 70%;
    }
    .all-payment .price-payment span {
        font-weight: 600;
    }

    /* container sub total */
    .container-total {
        width: 100%;
    }
    .container-total .subtotal {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .container-total .subtotal .text-total {
        width: 30%;
    }
    .container-total .subtotal .price-total {
        font-weight: 600;
        width: 70%;
    }


    .book-pay-now {
        margin-top: 20px;
        border: 1px solid #312783;
        width: 140px;
        border-radius: 4px;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        color: #312783;
        border-color: #312783;
        font-weight: 600;
        transition: 0.5s;
    }
    .book-pay-now:hover {
        border: 1px solid #DE5D0A;
        color: #fff;
        background: #DE5D0A;
    }

    .payment-frame {
        padding-left: 30px;
        padding-right: 30px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .set-wi-he {
        position: relative;
        width: 600px;
        height: 600px;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>