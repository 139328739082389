<template>
  <div class="blog">
    <div class="containers">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img @click="blog1"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt="" />
          </div>
          <div class="groupText" @click="blog1">
            <p>{{ $t('message.crossBorderInformationOrocedure') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    blog1() {
      localStorage.setItem("blog-img", 1);
      this.$router.push("/new-blog");
    },
    blog2() {
      localStorage.setItem("blog-img", 2);
      this.$router.push("/new-blog");
    },
    blog3() {
      localStorage.setItem("blog-img", 3);
      this.$router.push("/new-blog");
    },
  },
  created() { },
};
</script>

<style scoped>
.blog {
  height: 90vh;
}

.row {
  margin-top: 120px;
}

.card {
  margin-top: 20px;
  cursor: pointer;
}

.blog-img {
  width: 100%;
  height: 240px;
}

.blog-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-blog {
  cursor: pointer;
  width: 100%;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
}

.img-blog img {
  width: 100%;
  height: 100%;
}

.groupText {
  padding-top: 8px;
}

.groupText p:hover {
  color: #de5d0a;
}

@media (min-width: 1740px) {
  .set-heights {
    height: 500px;
  }
}

.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}

@media (width < 1024px) {
  .set-heights {
    height: 500px;
  }
}

@media (width < 450px) {
  .blog {
    height: 70vh;
  }

  .row {
    margin-top: 50px;
  }

  .no-result {
    font-size: 16px;
  }

  img {
    width: 86px;
  }

  .blog-img {
    height: 220px;
    border-radius: 6px;
  }

  .groupText p {
    font-size: 15px;
  }
}
</style>