<template>
    <div class="buytravelpackage">
        <!-- <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div> -->
      
        <div class="container-fluid"  style="background: #f2f2f2;">
          
            <div class="containers" style="padding-bottom: 70px;">
               
                <div class="row travelpackageheader">
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div>
                            <h1 class="text-center" style="color: #1D1D1D;">Travel Package </h1>
                        </div>
                        <div class="d-flex justify-center mt-6">
                            <div class="d-flex mobile-screen">
                                <div>
                                    <div><img src="@/assets/travel_package/1.svg" alt="" width="25px" style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number" style="background-color: #DE5D0A;   border: 1px solid #DE5D0A;">1</div> <img src="@/assets/travel_package/Line.svg" alt="" style="margin-left: 2px;">
                                    </div>
                                    
                                    <div class="title-pagination mt-1">Register Information</div>
                                </div>
                                <div>
                                    <div><img src="@/assets/travel_package/2.svg" alt="" width="25px" style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number" :class="{ 'box-color': showNewContent == 2 || showNewContent ==3}">2</div> <img src="@/assets/travel_package/Line.svg" alt="" >
                                    </div>
                                    
                                    <div class="title-pagination mt-1">Confirm Package</div>
                                </div>
                                <div>
                                    <div><img src="@/assets/travel_package/3.svg" alt="" width="25px" style="margin-left: 2px;"></div>
                                    <div class="number-img">
                                        <div class="number" :class="{ 'box-color': showNewContent == 3 }">3</div>
                                    </div>
                                    
                                    <div class="title-pagination mt-1">Confirm Payment</div>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div> 
                </div>
                <transition v-if="showNewContent == 1" name="slide" mode="out-in">
                    <div  key="box1">
                        <div class="row">
                            <div v-if="isCameraOpen" style="width: 100%; height: 100%;" class="text-center open-camera">
                                <video ref="cameraPreview" width="100%" height="auto" autoplay></video>
                                <!-- <button >Capture</button> -->
                                <button type="button" class="button-capture" title="capture" @click="capturePhoto">
                                    <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png" >
                                </button>
                                <button type="button" class="button-close"  title="close" @click="toggleCamera">
                                    <img src="@/assets/travel_package/close.svg">
                                </button>
                            </div>
                        </div>
                        <div class="row travelpackageheader pl-lg-10 pl-md-10">
                            <div class="col-lg-4 col-md-4 mobileScreenimage">
                                <div class="row screenrow d-flex justify-center">
                                    <div class="col-lg-8 col-md-8" style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile" >
                                            <img v-if="selectedFile" :src="imageUrl" alt="" width="128px" height="128px">
                                            <img v-else src="@/assets/travel_package/profile.svg" alt="" width="128px" height="128px">
                                        </div>
                                        <div class="text-center" style="color: #DE5D0A;">
                                            <p>* Choose File Image</p>
                                        </div>
                                        <div class="row text-center mt-2">
                                            <div class="d-flex justify-center" style="cursor: pointer; width: 45%;" @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg" alt="" style="margin-top: -2px;">
                                                    <img v-else src="@/assets/travel_package/close.svg" alt="" width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen" >Camera</span>
                                                    <span v-else>Close</span>
                                                </div>
                                                
                                            </div>
                                            <div style="width: 10%;">
                                                <img src="@/assets/travel_package/line-left.svg" alt="">
                                            </div>
                                            <div class="d-flex justify-center" style="cursor: pointer; width: 45%;" @click="openFileInput">
                                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt=""> 
                                                </div>
                                                <div class="ml-1" >
                                                    Gallery
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 fileshowcameraIpad">
                                <div class="row d-flex justify-center">
                                    <div class="col-lg-8 col-md-8 col-sm-8" style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile" >
                                            <img v-if="selectedFile" :src="imageUrl" alt="" width="128px" height="128px">
                                            <img v-else src="@/assets/travel_package/profile.svg" alt="" width="128px" height="128px">
                                        </div>
                                        <div class="text-center" style="color: #DE5D0A;">
                                            <p>* Choose File Image</p>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center" style="cursor: pointer;" @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg" alt="" style="margin-top: -2px;">
                                                    <img v-else src="@/assets/travel_package/close.svg" alt="" width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen" >Camera</span>
                                                    <span v-else>Close</span>
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2">
                                                <img src="@/assets/travel_package/line-left.svg" alt="">
                                            </div>
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center" style="cursor: pointer;" @click="openFileInput">
                                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt=""> 
                                                </div>
                                                <div class="ml-1" >
                                                Gallery
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label"><span style="color: #FF0000;">*</span> Enter Full Name</label>
                                            <input type="text" v-model="names" :class="{ 'is-invalid': isRegisterBuyPackage && $v.names.$error }" class="custom-input equals_input" placeholder="Enter full name">
                                            <div v-if="isRegisterBuyPackage && !$v.names.required" class="invalid-feedback">Name field is required</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label"><span style="color: #FF0000;">*</span> Gender </label>
                                            <div class="equals_input" style="position: relative;">
                                                <div style="position: relative;">
                                                    <input type="text" :class="{ 'is-invalid': isRegisterBuyPackage && $v.selectedOptionGender.$error }" @click="selectGender()" v-model="selectedOptionGender" readonly class="custom-input" placeholder="Select gender" style="cursor: pointer;">
                                                    <i @click="selectGender()" style="position: absolute;top: 34%;right: 9px; color: #6E6E73;"  class="fa-sharp fa-solid fa-caret-down"></i>
                                                </div>
                                                <div v-if="isRegisterBuyPackage && !$v.selectedOptionGender.required" class="invalid-feedback">Gender field is required</div>
                                                
                                                <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;" v-if="showListDataGen" v-click-outside="hidePopupGen">
                                                    <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(1)">Male</div>
                                                    <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(2)">Female</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                        <div class="group-form">
                                            <label for="" class="hide-label equal_label"> <span style="color: #FF0000;">* </span> Nationality</label>
                                            <div class="equals_input" style="position: relative;">
                                                <div style="position: relative;">
                                                    <input @click="selectNation()" readonly type="text" :class="{ 'is-invalid': isRegisterBuyPackage && $v.nataionlity.$error }" v-model="nataionlity" class="custom-input" placeholder="Nationality" style="cursor: pointer;">
                                                    <!-- <select class="custom-input" >
                                                        <option value="1">Non-Cambodian</option>
                                                    </select> -->
                                                    <i @click="selectNation()" style="position: absolute;top: 34%;right: 9px; color: #6E6E73;" class="fa-sharp fa-solid fa-caret-down"></i>
                                                </div>
                                                <div v-if="isRegisterBuyPackage && !$v.nataionlity.required" class="invalid-feedback">Nationality is required</div>
                                                <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;" v-if="showListDataNation" v-click-outside="hidePopupNation">
                                                    <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setNational(1)">Cambodian</div>
                                                    <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setNational(2)">Non-Cambodian</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label"><span style="color: #FF0000;">*</span> Email</label>
                                            <input type="email" :class="{ 'is-invalid': isRegisterBuyPackage && $v.email.$error }" v-model="email" class="custom-input equals_input" placeholder="Email">
                                            <div v-if="isRegisterBuyPackage && !$v.email.required" class="invalid-feedback">Email field is required</div>
                                            <div v-if="msg.email" style="color: #FF0000;">{{ msg.email }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label"><span style="color: #FF0000;">*</span>  Password </label>
                                            <input v-if="showPassword"  :class="{ 'is-invalid': isRegisterBuyPackage && $v.password.$error }" type="text"  v-model="password" class="custom-input equals_input" placeholder="Password"/>
                                            <input  :class="{ 'is-invalid': isRegisterBuyPackage && $v.password.$error }" v-model="password" v-else type="password" autocomplete="off" class="custom-input equals_input" placeholder="Password"/>
                                            <button type="button" class="button" @click="toggleShowPassword"><span class="icon is-small is-right">
                                                <i class="fas" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                                </span>
                                            </button>
                                            <div v-if="isRegisterBuyPackage && !$v.password.required" class="invalid-feedback">Password field is required</div>
                                            <div v-if="!$v.password.minLength" class="invalid-feedback">Password should be at least 6 characters long</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label">Date Of Birth <span style="color: #C6C6C6; "> (Optional)</span></label>
                                            <date-picker
                                                v-model="dob"
                                                format="YYYY-MM-DD"
                                                type="date"
                                                placeholder="Date of birth"
                                                class="date-picker-custom"
                                                readonly
                                            ></date-picker>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label">Telephone Number <span style="color: #C6C6C6; "> (Optional)</span></label>
                                            <div class="number-phone-box" style="display: flex; align-items: center; border-radius: 6px; border: 1px solid #C6C6C6;">
                                            <div style="width: 16%; height: 30px; display: flex; justify-content: center; align-items: center; border-right: 1px solid #C6C6C6;">
                                                +855
                                            </div>
                                            <div style="width: 84%;">
                                                <input @input="validatePhoneNumber" v-model="telephoneNumber" type="tel" class="custom-input equals_input form-password" placeholder="Telephone number"/>
                                            </div>
                                        </div>
                                        </div>
                                        <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{ phoneNumberError.phone }}</span>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-11 col-md-11 col-sm-11">
                                        <div class="group-form"> 
                                            <label for="" class="hide-label equal_label">Address <span style="color: #C6C6C6; "> (Optional)</span></label>
                                            <textarea name="" v-model="address" class="custom-input-textarea" id="" placeholder="Address"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-10 col-md-10 col-sm-10">
                                        <v-btn @click="toggleaddRegister" :disabled="disabled" style="border-radius: 6px; width:  30%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize btn-width btn-width-mobile reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> {{ titlecontent1 }} </span>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 fileshowcamera">
                                <div class="row d-flex justify-center">
                                    <div class="col-lg-8 col-md-8 col-sm-8" style="background-color: #FFFFFF; border-radius: 3px;">
                                        <div class="text-center show-profile" >
                                            <img v-if="selectedFile" :src="imageUrl" alt="" width="128px" height="128px">
                                            <img v-else src="@/assets/travel_package/profile.svg" alt="" width="128px" height="128px">
                                        </div>
                                        <div class="text-center" style="color: #DE5D0A;">
                                            <p>* Choose File Image</p>
                                        </div>
                                        <div class="row text-center">
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center" style="cursor: pointer;" @click="toggleCamera">
                                                <div>
                                                    <img v-if="!isCameraOpen" src="@/assets/travel_package/camera.svg" alt="" style="margin-top: -2px;">
                                                    <img  v-else src="@/assets/travel_package/close.svg" alt="" width="25px" height="25px" style="margin-top: -2px;">
                                                </div>
                                                <div class="ml-1">
                                                    <span v-if="!isCameraOpen"   class="fontsizeIpad">Camera</span>
                                                    <span v-else class="fontsizeIpad">Close</span>
                                                </div>
                                                
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2">
                                                <img src="@/assets/travel_package/line-left.svg" alt="">
                                            </div>
                                            <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center" style="cursor: pointer;" @click="openFileInput">
                                                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange">
                                                <div>
                                                    <img src="@/assets/travel_package/galary.svg" alt=""> 
                                                </div>
                                                <div class="fontsizeIpad ml-1" >
                                                    Gallery
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition v-else-if="showNewContent == 2" name="slide" mode="out-in">
                    <div key="box2">
                        <div class="row travelpackageheader pl-lg-10 pl-md-10" v-for="(item,i) in  listonepacakge" :key="i">
                            <div class="row" style="position: relative;">
                              
                                <ssr-carousel :slides-per-page='null' v-model='page'>
                                    <slide :index='1' class="col-lg-7 col-md-7 col-sm-7"  :style="{ 'margin-right': '0px !important' }">   
                                        <img src="@/assets/travel_package/pk1.png" alt="" width="100%" height="95%">       
                                            
                                    </slide>
                                    <slide :index='2' class="col-lg-5 col-md-5 col-sm-5" :style="{ 'margin-right': '0px !important','position':'relative' }">   
                                        <img src="@/assets/travel_package/pk2.png" alt="" width="100%" height="95%">         
                                    </slide>
                                    
                                </ssr-carousel>
                                <div @click='page++' class="d-flex align-center justify-center" style="position:absolute; right:1%; border-radius: 50%; padding: 22px; cursor: pointer; top: 40%; background: #00000040;">
                                    <img src="@/assets/arrows/arrow-right-white.svg" alt="">
                                </div> 
                            </div>
                            <div class="row" style="margin-top: 0px !important;">
                                <div class="col-lg-8 col-md-8 col-sm-8">   
                                    <div class="row ml-1 mb-2">
                                        <h1 style="color:#1D1D1D; margin-bottom: 8px;">{{ item.name }}</h1>
                                        <span class="mb-2">{{ item.description }}</span>
                                        <v-btn  style="border-radius: 6px; width:  25%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize screenlg btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> ${{ item.price }} </span>
                                        </v-btn>
                                    </div>    
                                    <div class="row ml-1">
                                        <h3 class="mb-2" style="color:#1D1D1D;">Term & Condition:</h3>
                                        <span class="mb-2">One Package travel only one person with unlimited trip valid for 30days to any destination in Cambodia
                                                with Vireak Buntham Express Travel / VET AIR BUS EXPRESS are operating from the selected date to start
                                                trip.
                                        </span>
                                        <p class="ml-1" style="line-height: 1.7 !important;">
                                           <ul>
                                                <li>Purchased Package are non-refundable but can be delayed the starting travel period, passenger must inform VET 24 hours before the package effective date.</li>
                                                <li>Seat not available on desire travel time/date are not subjected to refund/request other travel option beside the schedule shown on website or app.</li>
                                                <li>Passenger must book seat in advance or walk in directly to our terminal to book ticket for your desire destination.</li>
                                                <li>Tickets booked when Passenger want to cancel trip must inform the Provider 6 hours before departure time.</li>
                                                <li>Passenger book ticket and no show more than 3 times without any proper inform, the package will beatomically terminated.</li>
                                                <li>Passenger cannot book ticket to other person to travel when found by the Company, the package purchased will be automatically terminated.</li>
                                                <li>Passengers are required to arrive at the boarding point 15 minutes before the scheduled departure time for check in.</li>
                                           </ul>
                                        </p>
                                        <v-btn @click="toggleaddRegister" style="border-radius: 6px; width:  22%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 16px !important;"> Next </span>
                                        </v-btn>
                                        <!-- <v-btn @click="slideBack" style="border-radius: 6px; width:  22%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 16px !important;"> Back </span>
                                        </v-btn> -->
                                    </div>              
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">  
                                    <div class="row d-flex justify-end mr-2">
                                        <v-btn  style="border-radius: 6px; width:  35%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize destopscreen btn-width reposo-height">
                                            <span style="color: #FFF; font-size: 15px !important;"> ${{ item.price }} </span>
                                        </v-btn>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition v-else name="slide" mode="out-in">
                    <div key="box3">
                        <div class="row travelpackageheader pl-lg-10 pl-md-10">
                        <div class="col-lg-6 col-md-7 col-sm-12">
                            <div class="passDetails">
                                <div class="number">2</div>
                                <div class="title-pass">Payment Methods</div>
                            </div>
                            
                            <!-- <form action=""> -->
                            <div class="select-radio ml-3" style="margin-top: -12px;">
                                <v-radio-group  v-model="radioGroup">
                                    <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/KHQR.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title" style="line-height: 20px;">
                                                Payment KHQR
                                            </div>
                                            <div class="txt">
                                                Scan to pay with member bank app
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="5" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div>
                                    <!-- <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/ABA_PAY.svg" style="width: 40px;height: 30px;border-radius: 4px;" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"  style="line-height: 20px;">
                                                ABA Pay
                                            </div>
                                            <div class="txt">
                                                Scan to pay with member bank app
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="4" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div> -->
                                    <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/generic.svg" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title">
                                                Credit / Debit Card
                                            </div>
                                            <div class="txt">
                                                <img src="@/assets/4_Cards.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="6" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div>
                                
                                </v-radio-group>
                            </div>
                        <!-- </form> -->
                            <div class="row mt-4 ml-2">
                                <p style="font-size: 18px; font-weight: 500;">Cancellation Policy</p>
                                <span> <span style="font-weight: 600;"> NOTE:</span> Due to time zone differences, the confirmed cancellation date is based on the supplier’s local time. Please allow 2–5 business days to process your cancellation request, after which the cancellation fee will be charged according to the product’s cancellation policy. Once the cancellation is confirmed, you will receive your refund within 14 working days.</span>
                            </div>
                    
                      
                        </div>
                            <div class="row mt-2">
                                <div class="col-lg-10 col-md-10 col-sm-10 d-flex justify-lg-center justify-md-center">
                                    <v-btn style="border-radius: 6px; width:  35%; outline: none;" color="#DE5D0A" height="40px"  class="text-capitalize btn-width reposo-height">
                                        <span style="color: #FFF; font-size: 14px !important;"> Buy Package </span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </transition>
               
            </div>
        </div> 
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
      required,
      email,
      minLength
  } from "vuelidate/lib/validators";
  import axios from 'axios';
  import qs from 'qs';
  export default{
      components: { DatePicker },
      data(){
          return{
            
                index: 0,
                showListDataGen: false,
                showListDataNation:false,
                telephoneNumber: '',
                dob: null,
                selectedFile: null,
                showPassword: false,
                isCameraOpen: false,
                videoStream: null,
                showNewContent: 1,
                radioGroup: 5,
                listonepacakge:[],
                msg: [],
                titlecontent1:'Next',
                disabled:false,

                names:'',
                selectedOptionGender: '',
                selectedOptionGenderid:'',
                nataionlity:'',
                nationalityid:'',
                email:'',
                password:'',
                imageUrl: '',
                address:'',
                maxLength: 9, // maximum allowed phone number length
                maxLengths: 10, // maximum allowed phone number length
                phoneNumberError: [],
                // loading: true,
                isRegisterBuyPackage:false,
                page: 0,
                
          }
      },
      validations:{
            names: {required},
            selectedOptionGender: {required},
            nataionlity: {required},
            password: {required,minLength: minLength(6)},
            email: {required,email},
            // imageUrl:{required}
        },
      watch:{
        email(value) {
            // binding this to the data value in the email input
            this.email = value;
            this.validateEmail(value);
        },
        telephoneNumber(value) {
            this.telephoneNumber = value;
            this.validatePhoneNumber(value);
        },
      },
      methods:{
           
            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
            nextSlide() {
               this.page++;
               if(this.page>=this.page.length){
                    this.page=0;
               }
            },
            openFileInput() {
                this.$refs.fileInput.click();
            },
            handleFileChange(event) {
                this.selectedFile = event.target.files[0];
                if (this.selectedFile) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.imageUrl = reader.result;
                        // this.uploadFile(this.imageUrl);
                    };
                    reader.readAsDataURL(this.selectedFile);
                }
            },
          
            toggleCamera() {
                if (this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.stopCameraStream();
                } else {
                    this.isCameraOpen = true;
                    this.startCameraStream();
                }
            },
            startCameraStream() {
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(stream => {
                        this.$refs.cameraPreview.srcObject = stream;
                        this.videoStream = stream;
                    })
                    .catch(error => {
                        console.error('Error accessing camera:', error);
                    });
            },
            stopCameraStream() {
                if (this.videoStream) {
                    const tracks = this.videoStream.getTracks();
                    tracks.forEach(track => track.stop());
                }
            },
            capturePhoto() {
                const canvas = document.createElement('canvas');
                canvas.width = this.$refs.cameraPreview.videoWidth;
                canvas.height = this.$refs.cameraPreview.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(this.$refs.cameraPreview, 0, 0, canvas.width, canvas.height);
                
                // Convert the canvas content to a data URL (base64 encoded)
                const imageDataUrl = canvas.toDataURL('image/png');
                
                // Update the selectedFile and imageUrl
                this.selectedFile = this.dataURLtoFile(imageDataUrl, 'captured-photo.png');
                this.imageUrl = imageDataUrl;
                // console.log(this.imageUrl);
                // Stop the camera stream
                this.stopCameraStream();
                
                // Close the camera
                this.isCameraOpen = false;
            },
            dataURLtoFile(dataurl, filename) {
                const arr = dataurl.split(',');
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            },
            setGender(value) {
                this.index = value;
                // localStorage.setItem('gender',  this.index);
                if(this.index == 0) {
                    this.selectedOptionGender = "Gender";
                    this.selectedOptionGenderid = 0;
                }else if(this.index == 1){
                    this.selectedOptionGender = "Male";
                    this.selectedOptionGenderid = 1;
                }else{
                    this.selectedOptionGender = "Female";
                    this.selectedOptionGenderid = 2;
                }
                
                this.showListDataGen = false;
            },
            selectGender() {
                this.showListDataGen = true;
            },
           
            hidePopupGen() {
                this.showListDataGen = false;
            },

            //nationality
            setNational(value) {
                this.index = value;
                // localStorage.setItem('nationalityId',  this.index);
                if(this.index == 0) {
                    this.nataionlity = "Nationality";
                    this.nationalityid = 0;
                }else if(this.index == 1){
                    this.nataionlity = "Cambodian";
                    this.nationalityid = 1;
                }else{
                    this.nataionlity = "Non-Cambodian";
                    this.nationalityid = 2;
                }
                
                this.showListDataNation = false;
            },
            selectNation(){
                this.showListDataNation = true;
            },
            hidePopupNation() {
                this.showListDataNation = false;
            },
            async uploadFile(photo) {
                const url = this.$url + `file/upload-file`;
                const data = { 
                    'upload-file': photo,
                    'secret': this.$secret,
                }
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };
            
                axios(config).then((response) => {
                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            this.imageUrl = response.data.body.filename;
                           
                        }else{
                            alert('Photo is error');
                        }
                    } 
                }).catch((error) => {
                    console.log(error);
                });
            },
            async getTravelPackageOneregister(){
                const url = this.$url + `travel-package/find/`+this.$route.query.id;
                const data = { 
                    'id': this.$route.query.id,
                    'photoPath': this.$route.query.photo,
                }
                let config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(data),
                    url,
                };
            
                axios(config).then((response) => {
                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if(response.data.body.status == true || response.data.body.message == 'Success'){
                                this.listonepacakge = response.data.body.data;
                            }else{
                                alert(response.data.body.message)
                            }
                           
                        }
                    } 
                }).catch((error) => {
                    console.log(error);
                });
            },
            toggleaddRegister() {
                // console.log(this.dob.toISOString().slice(0, 10));
                // alert(1);
                // this.isRegisterBuyPackage=true;
                // this.$v.$touch();
                // if (this.$v.$invalid) {       
                //     return;       
                // }else if(this.imageUrl == ''){
                //     alert("Please Choose a photo");
                // }else{    
                    this.showNewContent = (this.showNewContent % 3) + 1; // Cycle through 1, 2, 3
                    // register package on form when 1 . register inform
                    // if(this.showNewContent==1){
                    //     if(/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)){
                    //         this.titlecontent1 = "Loading";
                    //         this.disabled = true;
                    //         const data = {
                    //             'name':this.names,
                    //             'sex':this.selectedOptionGenderid,
                    //             'nationality':this.nationalityid,
                    //             // 'photo':this.imageUrl,
                    //             'photo':'1.jpg',
                    //             'password':this.password,
                    //             'email':this.email,
                    //             'telephone':this.telephoneNumber,
                    //             'dob':this.dob.toISOString().slice(0, 10), // Format the date in 'YYYY-MM-DD' format
                    //             'address':this.address,
                    //         }
                    //         const url = this.$url + `travel-package/register`;
                    //         let config = {
                    //             method: "POST",
                    //             headers: {
                    //                 ...this.$header,
                    //                 "Content-Type": "multipart/form-data"
                    //             },
                    //             data: data,
                    //             url
                    //         };
                           
                    //         axios(config).then((response) => {
                    //             if (response.status == 200) {
                    //                 this.titlecontent1 = "Next";
                    //                 this.disabled = false;
                    //                 if(response.data.header.result == true && response.data.header.statusCode == 200) {
                    //                     if(response.data.body.status == true || response.data.body.message=='success'){
                    //                         this.showNewContent =2;
                    //                     }else{
                    //                         alert(response.data.body.message);
                    //                     }
                    //                 }else{  
                    //                    alert('Something went wrong, Please try it again.')
                    //                 }
                    //             }
                    //         }).catch((error) => {
                    //             console.log(error);
                    //         }); 
                    //     }else{
                    //         this.msg['email'] = 'Please enter a valid email address';
                    //         alert("Please enter a valid email address");
                    //     }
                    // }else{
                    //     this.showNewContent=3;
                    // }
                //    alert(this.showNewContent);
                   localStorage.setItem("showNewContent",this.showNewContent);
                 
                // }
               
               
            },

            validatePhoneNumber(value) {
                const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/; // regular expression to validate phone number
                if (regex.test(value)) {
                    if (value.length < this.maxLength) {
                        this.phoneNumberError['phone'] = 'Telephone number is invalid';
                    }else if(value.length <= this.maxLength || this.telephoneNumber.length == this.maxLengths){ 
                        this.phoneNumberError['phone'] = ''; // valid phone number within length limit
                    }else {
                        this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number exceeding length limit
                    }
                } else {
                    this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number
                }
            },
           
            validateEmail(value) {
                if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                    this.msg['email'] = '';
                } else{
                    this.msg['email'] = 'Email is invalid';
                }
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
      },
      created(){ 
          localStorage.setItem("showNewContent",this.showNewContent);
          this.scrollToTop();
          setInterval(() => {
                this.nextSlide();
            }, 5000);
          this.getTravelPackageOneregister();
         
          
      },
      mounted() {
        // handle back page
        // console.log('mounted');
        window.onpopstate = () => {
            // console.log(this.showNewContent);
            if(this.showNewContent>1){
                this.showNewContent--;
                localStorage.setItem("showNewContent",this.showNewContent);
            }
        };
      },
      beforeUnmount() {
        window.onpopstate = null;
      },
  }
</script>
<style scoped>
  .buytravelpackage {
        padding-top: 55px;
  }
  .invalid-feedback{
        color: #FF0000;
    }
    .is-invalid{
        border: 1px solid #FF0000 !important;
    }
  .travelpackageheader{
      padding-top: 35px;
      /* padding-bottom: 70px; */
      /* box-sizing: border-box; */
  }
  .button{
        margin-left: -28px;
        color: #B7B5B5;
    }
  
  .open-camera{
    position: relative;
  }
  .select-radio .card-select-payment {
        position: relative;
        background: #EEEEF3;
        margin-top: 12px;
        padding: 17px;
        border-radius: 6px;
        display: flex;
        align-items: center;
    }
    .select-radio .card-select-payment .text .title {
        font-weight: 600;
        font-size: 12px !important;
        color: #424245;
    }
    .passDetails {
        display: flex;
        align-items: center;
    }
    .passDetails .number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #312783;
        background: #312783;
    }
    .passDetails .title-pass {
        margin-left: 12px;
        font-weight: 600;
        font-size: 18px;
        color: #0c2f54;
    }
  .select-radio .card-select-payment .text .txt {
        font-weight: 400;
        font-size: 12px !important;
        color: #6E6E73;
    }
    .select-radio .card-select-payment .img-card {
        width: 10%;
    }
    .select-radio .card-select-payment .text {
        width: 80%;
    }
  .button-capture{
    position: absolute;
    bottom: 5%;
    right: 41%;
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    /* border-radius: 50%; */
    border-radius: 5%;

  }
  .slide-enter-active, .slide-leave-active {
    transition: transform 0.5s;
    }
    .slide-enter, .slide-leave-to {
    transform: translateX(-100%);
    }
    .slide-enter-to, .slide-leave {
    transform: translateX(0%);
    }
    .slide-enter, .slide-leave-from {
    transform: translateX(100%);
    }
  .mobileScreenimage{
    display: none;
  }
  .fileshowcameraIpad{
    display: none !important;
  }
  .screenlg{
    display: none !important;
  }
  .button-close{
    position: absolute;
    bottom: 5%;
    left: 41%;
    width: 80px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    /* border-radius: 50%; */
    border-radius: 5%;
    height: 35px;
  }
  .button-close img{
        height: 35px;
        object-fit: cover;
  }
  .button-capture img{
        height: 35px;
        object-fit: cover;
  }
  
  .number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #312783;
        background: #312783;
    }
    .box-color{
        background-color: rgb(222, 93, 10);
        border: 1px solid rgb(222, 93, 10);
    }
    .title-pagination{
        margin-left: -40px;
        color: #1D1D1D;
    }

    .mobile-screen{
        margin-left: 32px !important;
    }
    .custom-input {
        /* cursor: pointer; */
        padding-left: 12px;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        outline: none;
        border: 1px solid #C6C6C6;
    }
    .custom-select {
        margin-top: 0px;
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        height: 40px;
        line-height: 40px;
        padding-top: 5px;
    }
    .hover-color:hover {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 12px;
        color: #ffffff;
        background: #DE5D0A;
    }
    .form-password{
        border: none !important;
        border-radius: none !important;
    }
    .mx-datepicker-popup{
        top: 599px !important;
    }
    .mx-datepicker {
        width: 100%;
    }
    .mx-datepicker-body .mx-calendar {
        width: 281px;
    }
    .custom-input-textarea{
        padding-left: 12px;
        padding-top: 5px;
        border-radius: 4px;
        width: 100%;
        height: 100px;
        outline: none;
        border: 1px solid #C6C6C6;
    }
    
    /* .date-picker-custom{
        background-color: #f2f2f2 !important;

    } */
    /* @media screen and (max-width: 1169px) and (min-width: 950px){
        .fileshowcameraIpad{
        display: block !important;
      }
    } */
   
    @media screen and (max-width: 961px) and (min-width: 700px){
      /* .buytravelpackage {
          margin-top: -20px;
      } */
      .number-img img{
        width: 230px;

      }
      .fileshowcameraIpad{
        display: block !important;
      }
      .fileshowcamera{
        display: none;
      }
      .button-capture{
            left: 52% !important;
        }
        .button-close{
            left: 34% !important;

        }
      

    

  }
  @media screen and (width < 700px){
      /* .buytravelpackage {
          margin-top: -20px;
      } */
      .number-img img{
        width: 160px;

      }
      .button-capture{
            left: 55% !important;
        }
        .button-close{
            left: 30% !important;

        }
        .mobileScreenimage{
            display: block !important;
        }
        /* .destopscreen{
            display: none;
        } */
      .fileshowcamera{
        display: none;
      }
      /* .screenlg{
            display: block !important;
        } */

  }
  @media screen and (width < 600px){
    .screenlg{
        display: block !important;
    }
        .destopscreen{
        display: none !important;
    }
  }
 
  @media screen and (width < 500px){
      /* .buytravelpackage {
          margin-top: -20px;
      } */
     
      .travelpackageheader{
          padding-top: 20px;
          padding-bottom: 40px;
          opacity: 1;
      }
      .number-img img{
        width: 100px;

      }
      .title-pagination{
        font-size: 10px;
        margin-left: -25px;
        }
        .mobile-screen{
            margin-left: 5px !important;
        }
        .fileshowcamera{
            display: none;
        }
        .mobileScreenimage{
            display: block !important;
        }
        .button-capture{
            left: 55% !important;
        }
        .button-close{
            left: 30% !important;

        }
        .fileshowcameraIpad{
            display: none !important;
        }
        .screenlg{
            display: block !important;
        }
         .destopscreen{
            display: none !important;
        }
        .select-radio .card-select-payment {
            padding: 8px;
        }
        .passDetails .title-pass {
            font-size: 13px;
        }
        .select-radio .card-select-payment .text {
            
            padding-left: 18px;
        }
        .btn-width-mobile{
            width: 30%;
        }
        
  }

  @media screen and (width < 438px){
      /* .buytravelpackage {
          margin-top: -20px;
      } */

      .travelpackageheader{
          padding-top: 20px;
          padding-bottom: 40px;
          opacity: 1;
      }
      .number-img img{
            width: 100px;
        }
        .title-pagination{
            font-size: 10px;
            margin-left: -25px;
        }
        .fileshowcameraIpad{
            display: none !important;
        }
        .mobile-screen{
            margin-left: 5px !important;
        }
        .fileshowcamera{
            display: none;
        }
        .mobileScreenimage{
            display: block !important;
        }
        .button-capture{
            left: 55% !important;
        }
        .button-close{
            left: 30% !important;

        }
        .screenlg{
            display: block !important;
        }
       
     
  }
  @media screen and (width < 372px){
        .mobile-screen{
            margin-left: 17px !important;
        }
        .screenrow{
            margin-top: -40px !important;
        }
    }   
</style>