export default {
    message: {
        vireakButhamExpress: 'Vireak Buntham Express',
        home: 'Home',
        aboutUs: 'About Us',
        speedBoat: 'Speed-Boat',
        resort: 'Resort',
        rentalService: 'Vehicle Rental',
        travelPackage: 'Travel Package',
        gallery: 'Gallery',
        blogs: 'Blog',
        contact: 'Contact',
        english: 'English',
        khmer: 'Khmer',
        chinese: 'Chinese',
        login: 'Login',
        register: 'Register',
        registerAccount: 'Register Account',
        loginRegister: 'Login / Register',
        profileAccount: 'Profile Account',
        travelPackageHistory: 'Travel Package History',
        ticketHistory: 'Ticket History',
        logout: 'Log Out',
        cambodian: 'Cambodian',
        nonCambodian: 'Non-Cambodian',
        thailand: 'Thailand',
        vietnam: 'Vietnam',
        laos: 'Laos',
        departingFrom: 'Departing From',
        goingTo: 'Going To',
        returnDate: 'Return Date',
        findNow: 'Find Now',
        information: 'Information',
        pleaseSelectDeparture: 'Please Select Departure',
        ok: 'OK',
        pleaseSelectGoingTo: 'Please Select Going To',
        flashSale: 'Flash Sale',
        applyPeriod: 'Apply Period',
        to: 'To',
        from: 'From',
        modify: 'Modify',
        book: 'Book',
        bookNow: 'Book Now',
        booked: 'Booked',
        whyYouChooseUs: 'Why Choose Us',
        DesWhyYouChooseUs: 'Our schedule covers the major destinations in Cambodia and also operate to Bangkok and Ho Chi Minh City.',
        complimentary: 'Complimentary',
        desComplimentary: 'We offer a bottle of pure drinking water on board',
        liabilityInsurance: 'Liability Insurance',
        desLiabilityInsurance: 'All of our fleets are covering by liability insurance both domestic and international route.',
        powerUsbCharger: 'Power & USB Charger',
        desPowerUsbCharger: 'All our fleet equip individual power outlet or USB charger to make sure your device battery is fully charged during trip.',
        customerService: 'Customer Service',
        desCustomerService: 'Our 24h call center rep are ready to answer all your question related to your trip both Khmer & English speaking.',
        airConditioned: 'Air Conditioned',
        desAirConditioned: 'Cambodia is hot country, the air-condition is always check up by our professional indoor mechanic to confirm we are not ruining your trip.',
        paymentTicket: 'Payment & Ticket',
        desPaymentTicket: 'You can purchase our ticket and pay real time with varieties of payment method and our e-ticket will send to your email.',
        comfortableSeat: 'Comfortable Seat',
        desComfortableSeat: 'Generally, van is equipping the seat up to 17 Seats but we want you to have more space we eliminated 6 seats out to have enough room and reclinable.',
        gpsTrackingSystem: 'GPS Tracking System',
        desGpsTrackingSystem: 'To ensure our driver operate the bus compliance to speed limited, our full-time tracking staffs are monitoring all of our fleets.',
        schedule: 'Schedule',
        desSchedule: 'We are top range in Cambodia with varieties of schedule from city-to-city in Cambodia, Bangkok and Ho Chi Minh which make you convenience to plan your trip.',
        ourDriver: 'Our Driver',
        desOurDriver: 'Our driver is professionally recruited & trained by our HR team to ensure our driver is in good shape to operate the bus safely.',
        exporeTrendingPlace: 'Explore Trending Places',
        localRoute: 'Local Route - Cambodia',
        internationalRoute: 'International Route - Thailand & Vietnam',
        hqAddress: 'HQ Address',
        email: 'Email',
        tel: 'Tel',
        followUs: 'Follow Us',
        weAccepted: 'We Accept',
        termCondition: 'Terms & Conditions',
        privacyPolicy: 'Privacy Policy',


        galleryVetExpress: 'Gallery VET Express',
        hotelBus: 'Hotel Bus',
        luxuryCoaster: 'Luxury Coaster',
        luxuryHotelBus: 'Luxury Hotel Bus',
        luxuryVanH350: 'Luxury Van-H350',
        luxuryVanHiace: 'Luxury van-Hiace',


        tubeResort: 'Tube Resort',
        desTubeResort: 'Facing the beachfront, Tube Resort offers 4-star accommodation in Koh Rong Sanloem and features an outdoor swimming pool, garden and restaurant. Each accommodation at the 4-star hotel has garden views, and guests can enjoy access to a bar and to a private beach area.The accommodation provides a 24-hour front desk, airport transfers, room service and free WiFi throughout the property. The resort offers air-conditioned rooms with a desk, a kettle, a fridge, a minibar, a safety deposit box, a flat-screen TV, a terrace and a private bathroom with a shower. Tube Resort features certain units with sea views, and rooms include a balcony. At the accommodation every room is fitted with bed linen and towels.',
        sandyBeachBungalows: 'Sandy Beach Bungalows',
        desSandyBeachBungalows: 'Sandy Beach Bungalows is a quiet beachfront property situated on the island of Koh Rong Samloem, 20 km from Sihanoukville. The resort has a private beach area and water sports facilities.',
        kohSdachResort: 'Koh Sdach Resort',
        desKohSdachResort: 'Koh Sdach Resort features a garden, terrace, a restaurant and bar in Kaoh Sdach. This 4-star hotel offers a private beach area and a concierge service. The accommodation provides room service, a 24-hour front desk and currency exchange for guests.',
        edenBeachResort: 'Eden Beach Resort',
        desEdenBeachResort: 'Facing the beachfront, Eden Beach Resort offers 4-star accommodation in Koh Rong Sanloem and features an outdoor swimming pool, garden and restaurant. With free WiFi, this 4-star hotel offers room service and a 24-hour front desk. The accommodation provides a concierge service, a tour desk and currency exchange for guests. The hotel will provide guests with air-conditioned rooms offering a desk, a safety deposit box, a flat-screen TV, a terrace and a private bathroom with a shower.Eden Beach Resort offers certain rooms with sea views, and each room is equipped with a balcony. Guest rooms at the accommodation are equipped with a seating area.',
        sarasea: 'Sarasea',
        desSarasea: 'Facing the beachfront, SARASEA Koh Rong Samloem offers 3-star accommodation in Koh Rong Sanloem and features a garden, terrace and bar. Featuring a private beach area, the 3-star resort has air-conditioned rooms with free WiFi, each with a private bathroom. The accommodation provides a 24-hour front desk, a concierge service and currency exchange for guests. At the resort, each room includes a patio. SARASEA Koh Rong Samloem features certain units with sea views, and the rooms have a balcony. At the accommodation every room has bed linen and towels.',
        bodegaResort: 'One Beach Resort',
        desBodegaResort: 'If you’re looking for a relaxing time on a tropical beach then Bodega Resort has everything you’re looking for! At the front of our resort is the beautiful and secluded Sandy Beach. Here you can enjoy relaxing on a 1.2km stretch of beach overlooking the ocean. Behind the property is the unspoiled jungles.',
        pidomaResort: 'Pidoma Resort',
        desPidomaResort: 'Pidoma Resort has an outdoor swimming pool, fitness centre, a garden and terrace in Sen Monorom. Featuring a restaurant, the 5-star resort has air-conditioned rooms with free WiFi, each with a private bathroom. The accommodation features room service, a 24-hour front desk and currency exchange for guests. At the resort, rooms are equipped with a desk, bed linen and a balcony with a mountain view. Featuring a shared bathroom with a bidet and free toiletries, rooms at Pidoma Resort also boast a city view. The rooms will provide guests with a wardrobe and a kettle.',
        romdoulKiriromResort: 'Romdoul Kirirom Resort',
        desRomdoulKiriromResort: 'Romdoul Kirirom Resort is a newly built 4 star mountain top resort in Kirirom Nataional Park. Located at an alititude of 800-850m, the area offers a fresh and unique experience. With spectacular views towards the Cardamom Mountains the detached bungalows are secludedly positioned amongst the pine forest with clean mountain air to invigorate your senses.Resort facilities include large swimming pool with sun deck and sun beds, Pool Bar serving cocktails and snacks. The Resort also has a large mountain view Restaurant serving Asian & International Food & Drinks. There is also a Coffee shop, Chinese Tea Shop, Bakery & Mini Mart on site. There are plans for expansion which include Fitness Centre, Tree House Sky Bar & Conference Room.',
        kohApikjunResort: 'Koh Apikjun resort',
        desKohApikjunResort: 'A magnificent island haven tucked away in stunning natural setting Located 8 kilometers south of the Koh Kong Province is your destination. Koh Apikjun Resort is a picture perfect hideaway, we provide pier-side overwater villas with a private pool and a special close-up view of the vivid turquoise sea.We also have luxurious, spacious on-land accommodations that are ideal for a private getaway with your family.',


        service: 'SERVICE',
        vehicleRentalInCambodiaThailandVietnam: 'Vehicle Rental in Cambodia, Vietnam & Thailand',
        desVehicleRentalInCambodiaThailandVietnamLess: 'VET Air Bus rental is providing premium fleet rental through the country with safe and reliable service as require itinerary. We also offer charter service from Cambodia to Bangkok & Ho Chi Minh city.There are varieties of fleet such as Luxury Bus, Mini Bus and Van.',
        desVehicleRentalInCambodiaThailandVietnamMore: 'VET Air Bus rental is providing premium fleet rental through the country with safe and reliable service as require itinerary. We also offer charter service from Cambodia to Bangkok & Ho Chi Minh city.There are varieties of fleet such as Luxury Bus, Mini Bus and Van. Both Thailand & Vietnam border process is more convenience, we have our staff at the border to process VIP service for exit and entry visa stamp included with the charter fee.',
        seeLess: 'See Less',
        seeMore: 'See More',
        askInfoOrBookVia: 'Ask info or book via',
        hyundaiUniverseNoble: 'Hyundai Universe Noble',
        premiumHyundaiSolatiH350: 'Premium Hyundai Solati H350',
        classicHyundaiSolatiH350: 'Classic Hyundai Solati H350',
        luxuryHyundaiH1: 'Luxury Hyundai H1',
        classicHyundaiH1: 'Classic Hyundai H1',
        hyundaiCounty: 'Hyundai County',
        seatingCapcity: 'Seating capacity: Up to 41 passengers',
        seatPlan: 'Seat Plan: 2 + 2',
        model: 'Model: Hyundai Universe Noble',
        production: 'Production Year: 2010',
        gpsTracking: 'GPS Tracking System: Yes',
        restroom: 'Restroom: Yes',
        refrigeator: 'Refrigerator: Yes',
        powerOutlet: 'Power Outlet: Yes',
        wifiVR: 'Wi-Fi: Yes',
        soundSystem: 'Sound System: Yes',
        drivingLicense: 'Driving License: D4',
        insuranceVR: 'Insurance: Passenger Liability',
        usbCharger: 'USB Charger: Yes',
        reservationNow: 'Reservation Now...',

        bookingDetail: 'Booking Detail',
        fullName: 'Full name',
        telephoneNumber: 'Telephone Number',
        journeyType: 'Journey Type',
        oneWay: 'One Way',
        roundTrip: 'Round Trip',
        trip: 'Trip',
        destination: 'Destination',
        selectDeparture: 'Select departure',
        selectArrival: 'Select arrival',
        departureDate: 'Departure Date',
        selectDepartureDate: 'Select departure date',
        selectReturnDate: 'Select return date',
        amountOfCar: 'Amount of car',
        remark: 'Remark',
        save: 'Save',
        vehicleTyle: 'Vehicle Type',


        companyProfile: 'Company Profile',
        enabling: 'Enabling',
        KeySuccess: 'Key Success',
        vetBackground: 'VET Background',
        desVetBackground1: 'Vireak Buntham Express Co., Ltd (VET) is a leading transportation and logistics company with a number of offices spread across Cambodia, and founded in 2004 by Neak Oknha Suo Vireak, and was registered as a company in 2006 at Koh Kong Province. VET provides a transportations and logistics crossing Cambodia, Thailand, and Vietnam. VET utilizes modern technology to deliver exceptional services.',
        desVetBackground2: 'VET started with the few sleeping buses and vans, with the few staff in Koh Kong Province, and then VET has invested on luxury hotel buses, Toyota Hiace vans for passengers transportation, and trucks for logistics to customize its clients.',
        desVetBackground3: 'The 2020 and 2021 were the historical expansion year for VET to transform very fast as the top leading logistic company in Cambodia even VET was facing with pandemic covid 19. We believe that the success of our clients will help us succeed. Our values reflect the thinking of the top management, which is to put our client’s interests ahead of our own.',
        desVetBackground4: '2022 marked a triumphant return for VET Travel as passenger services resumed post-pandemic. Continuing its ambitious expansion, VET expanded its branch network and invested in a magnificent new Head Office to ensure employee comfort and well-being.',
        desVetBackground5: 'Committed to sustainability, VET has begun introducing electric trucks in 2024 to reduce operating costs. Looking ahead, the company sets its sights on connecting Phnom Penh and Siem Reap to Laos, expanding its regional reach and further cementing its legacy as a pioneer in transportation and logistics.',
        readMore: 'Read More',
        neakOknhaSuoVireak: 'Neak Oknha Suo Vireak',
        founderChairmanCEO: 'Found, Chairman & CEO',

        vetMilestone: 'VET Milestone',
        desVetMilestone: 'Vireak Bustham Express Co., Ltd (VET) is a leading logistic and passenger transportation company in Cambodia that has fruitfully been developed itself as the following period',
        vetMilestone2024: 'Year 2024',
        vetMilestone2024List1: 'Year 2024',
        vetMilestone2024List2: 'start using EV trucks',
        vetMilestone2023: 'Year 2023',
        vetMilestone2023List1: 'The year of branches strengthening',
        vetMilestone2023List2: 'The year of expanding franchise',
        vetMilestone2022: 'Year 2022-Franchise Year',
        vetMilestone2022List1: 'Sell franchise to agencies',
        vetMilestone2022List2: 'Start new VET AIR BUS Express',
        vetMilestone2022List3: 'VET vechicle rental services',
        vetMilestone2021: 'Year 2021-Histrorical Year-The Year of D2D',
        vetMilestone2021List1: 'Branches and agencies expansion',
        vetMilestone2021List2: 'Investment on trucks',
        vetMilestone2021List3: 'Improved B2B, D2D, COD',
        vetMilestone2020: 'YEAR 2020-Hostroical Year of Covid 19',
        vetMilestone2020List1: 'Setup agencies for goods transfer in district and commune',
        vetMilestone2020List2: 'New services: D2D, B2B, and COD',
        vetMilestone2019: 'YEAR 2019-Applied Online System',
        vetMilestone2019List1: 'Online system on ticket and logistics',
        vetMilestone2019List2: 'Year of covid 19',
        vetMilestone2018: 'YEAR 2018-The Expansion Year',
        vetMilestone2018List1: 'Invested on Van',
        vetMilestone2018List2: 'Invested on Hotel-Bus',
        vetMilestone2018List3: 'Invested on Truck',
        vetMilestone2017: 'YEAR 2017-The Expansion Year',
        vetMilestone2017List1: 'Invested on Van',
        vetMilestone2017List2: 'Invested on Hotel-Bus',
        vetMilestone2017List3: 'Invested on Truck',
        vetMilestone2016: 'YEAR 2016',
        vetMilestone2016List1: 'The year of software development (off line system)',
        vetMilestone2016List2: 'Development logistic software and ticket software',
        vetMilestone2015: 'YEAR 2015',
        vetMilestone2015List1: 'The year of logistic',
        vetMilestone2015List2: 'Started logistics',
        vetMilestone2015List3: 'Invested on truck',
        vetMilestone2015List4: 'Invested on warehouse',
        vetMilestone2014: 'YEAR 2014',
        vetMilestone2014List1: 'Invested on day van',
        vetMilestone2014List2: 'Toyotal hiace',
        vetMilestone2014List3: 'Sleeping bus',
        vetMilestone2014List4: 'Hotel bus',
        vetMilestone2014List5: 'Luxury bus',
        vetMilestone2010: 'YEAR 2010',
        vetMilestone2010List1: 'Invested on night buses',
        vetMilestone2010List2: 'Rent bus from VCD',
        vetMilestone2010List3: 'Expand direction to PP',
        vetMilestone2010List4: 'SHV, SR, BTB, Poipet',
        vetMilestone2006: 'YEAR 2006',
        vetMilestone2006List1: 'Business registration',
        vetMilestone2006List2: '6 vehicles',
        vetMilestone2006List3: 'Koh kong, sihanouk ville',
        vetMilestone2004: 'YEAR 2004',
        vetMilestone2004List1: 'Business started up',
        vetMilestone2004List2: 'Koh kong province',
        vetMilestone2004List3: '3 vehicles',


        visionMission: 'Vision and Mission',
        vetVisionMission: 'VET vision and mission',
        desVisionMision: 'Based on our firm vision to participate with the royal government for transportation sector, we committed to provide the best transportation system which is safe, reliable, efficient, environmentally friendly and satisfying to both users and operators on travel and logistics services.',
        ourSlogan: 'Our Slogan',
        ourSloganList1: 'Any time, any where are safer and faster!',
        ourSloganList2: 'Your safety is our life, your property is our asset!',
        ourSloganList3: 'The growth on your business is our commitment!',
        vision: 'To be the best transportation company in Asia by apply insight, service quality, and innovation to create sustainable growth for business and society.',
        mission: 'Connecting people, business and communities to be a better future through out logistics and travel',
        coreValue1: 'Connected',
        coreValue2: 'Committed',
        coreValue3: 'Creative',
        coreValue4: 'Trust',

        messageFromChairmanCeo: 'Message from Chairman & CEO',
        messageFrom: 'Message from',
        chairmanCeo: 'Chairman & CEO',
        desMessageFromChairmanCeo: 'Vireak Buntham Express was founded on the unique vision of providing customized, innovative, end-to-end logistics and travel solutions of the highest quality. It is with this vision in mind that every member of the VET’s team dedicates their daily work.',
        desMessageFromChairmanCeoP: 'Vireak Buntham Express was founded on the unique vision of providing customized, innovative, end-to-end logistics and travel solutions of the highest quality. It is with this vision in mind that every member of the VET’s team dedicates their daily work.<br /> I am proud to every project assigned to us and to support our teams’ contribution to stand behind all your success. I firmly believe that it is our passion of surpassing customers’ expectations that has enabled us to become the world-class logistics service provider that we are now.<br /> During our years of development, VET Express has fully utilized advanced technologies, our extensive experience in land transportation, warehousing and supply chain management services to set VET Express apart from our competitors. These characteristics provide us with a significant competitive edge in offering the ‘Best-In-Class’ integrated supply chain solutions with seamless and transparent operations.',
        desMessageFromChairmanCeoP1: `With VET Express as your partner, you can rest assured that you are placing even the most complex logistics program in good hands – confident that we will proactively handle even the most complicated situations with solutions that ensure your parcels reaches its destination safely and on time.<br /> Thank you, to all who have put their trust in us. I believe in our bright future filled with opportunities and challenges and that VET Express will continually deliver on our promises and strive to perform with integrity and professionalism. You can expect consistently high-quality logistic solutions from us anytime, anywhere.`,
        desMessageFromChairmanCeoP2: 'Yours sincerely,',
        desMessageFromChairmanCeoP3: 'Neak Oknha. Suo Vireak',
        desMessageFromChairmanCeoP4: 'Chairman & CEO',

        vetCorporationCoreValues: 'VET Corporation Core Values',
        coreValues: 'Core values',
        desVetCorporationCoreValues: `VET's core values are the clearly stated principles about its vision, mission, and principles. That way, everyone is aligned around a guiding philosophy to serve employees, customers, and the broader community.`,
        vetCorporationCoreValuesConnected: `Connected:`,
        vetCorporationCoreValuesConnectedP: `We invest in insight to get to the heart of our customer's challengs. We are open and transparent in the way we work.`,
        vetCorporationCoreValuesCommitted: 'Committed:',
        vetCorporationCoreValuesCommittedP: `Deeply involved in building relationship everything we do is with the long-term in mind. Our dedication to quality is the cornerstone of our success we get every detail right.`,
        vetCorporationCoreValuesCreative: 'Creative:',
        vetCorporationCoreValuesCreativeP: `We are constantly developing better ways of working. If we find a better way, we act upon it and share it.`,
        vetCorporationCoreValuesTrust: `Trust:`,
        vetCorporationCoreValuesTrustP: `trust is everything, and we know how precious and how potentially fragile it is. As a result, every action we take is geared towards building and strengthening the trust our clients put in us. We believe the way to maintain our client's trust is to exceed their expectation in every single interaction.`,
        
        vetProductServices: 'VET Product and Services',
        desVetProductServices: 'Vireak BunTham Express Co., Ltd has expanded it services every yearfrom 2004 to today and has produced many results for market demand and seeking the long term vision of the manegement, today we have all services such as:',
        vetTravelPassengerTransprtationService: '1. VET Travel: Passengers Transportation Services',
        luxuryHotelBusPageService: 'Luxury Hotel Bus:',
        luxuryHotelBusPageServiceP: `It is a night bus that departures every night. There are Double Bed, Single Bed, Pillow, Blanket, Charger, and Toilet`,
        vipVanToyotaHiace: 'VIP VAN Toyota Hiace, Hyundai H350, Counties, and H1:',
        vipVanToyotaHiaceP: `There are a day mini buses with a wide-sofa seats from 07 to 17 Seats.`,
        vetAirBusPageService: 'VET Air Bus:',
        vetAirBusPageServiceP: `The luxury bus with sofa seat, there are wifi, snack, coffee charger, private tablet and toilet inside.`,
        busRentalService: 'Bus Rental Services:',
        busRentalServiceP: `VET provide a rental services such as tour and any travel with a reasonable fee.`,
        allPassengerAre: 'All passengers are our responsibility, they are taken care and insured.',
        AllpassengerAreOur: 'All passengers are our responsibility, they are taken care and insured.',
        whereCaWeBuyATicket: 'Where can we buy a tickets?',
        theNearbyBranche: 'The nearby branches',
        HotlinePhoneNumber: 'Hotline 081 911 911, or',
        appBookingName: 'App booking: VET Express, or',
        websiteName: 'Go to wwww.vireakbuntham.com',
        vetLogistic: '2. VET Logistic',
        vetExpressServiceToanChet: 'VET EXPRESS SERVICES (Toan Chet Services):',
        vetExpressServiceToanChetP: `Goods Transfer services through out the country (25 Provincial and city and districts, communes and rural areas) not over 12 hours.`,
        vetNormalService: 'VET NORMAL SERVICES:',
        vetNormalServiceP: `Goods Transfer services through out the country (25 Provincial and city and districts, communes and rural areas) not over 24 hours.`,
        goodsTransferFreezerTruck: 'Goods Transfer in Freezer Truck:',
        goodsTransferFreezerTruckP: 'Help maintain the quality of your vegetables, fish, meat, seafood to maintain good quality.',
        deliveryService: 'Delivery Services D2D, D2B,and B2B:',
        deliveryServiceP: 'Delivery man will pick up the goods from your location to deliver to your customers or partners crossing 25 provinces and city, including Thailand, as well.',
        cashOnDelivery: 'Cash on Delivery (COD):',
        cashOnDeliveryP: 'Delivery man and staff will deliver the goods to the location of your customers and clear payment instead of you, then transfer money back to you not over 2 days.',
        insuredGoodTransfer: 'Insured Goods Transfer:',
        insuredGoodTransferP: 'All insured goods transfer are liable and reimbursed in case of damage or loss. The company has an additional 100% insurance service in case of damage or loss. Charge 1% of the price of the goods.',
        GoodsTransferCrossingBorder: 'Goods Transfer Crossing Borders:',
        GoodsTransferCrossingBorderP: 'Goods transfer from Thailand and Vietnam to Cambodia’s 25 Provincial and City not over 48 hours.',


        vetMembershipCard: 'VET Membership Card',
        vetMembershipCard1: 'Register as Membership Card for the mutual discount rate. (Ex: 5%, 10%)',
        vetMembershipCard2: 'Register as a Membership Card to get a discount by packages of transfer from 5% to 20%',
        vetMembershipCard3: 'Register as a Membership card to get discount, transferring 10 packages, get one package free.',
        note: 'Note',
        noted: 'Noted',
        vetMembershipNote1: 'The 1st and 2nd : The discount amount will be the savings in your account, the discount amount will be calculated and returned to your account at the end of month. The next month transfer will be deducted from your account until balance is insufficient. This saving account can be paid for Bus Ticket and boat tickets, as well.',
        vetMembershipNote2: 'Get VIP service, get special attention',
        howRegisterMembership: 'How to register as Membership?',
        howRegisterMembership1: 'Please contact Tel: 010 522 522',
        howRegisterMembership2: 'Facebook Page: Virak Buntham Express Tour & Travel',
        howRegisterMembership3: 'Your transfer is over 300 parcels a month, our call center will directly contact to you.',
        howRegisterMembership4: 'Tell us about your shop and phone contact for registration.',

        whyPartnerWithUs: 'Why partner with us?',
        saveTime: 'Save time, save money, no longer than 12 hours',
        saveTime1: 'Customer services',
        saveTime2: '24/7 services',
        saveTime3: 'On time response',
        saveTime4: 'On time delivery',
        saveTime5: 'Membership',
        saveTime6: 'Discount rate',
        competitiveRate: 'Competitive rate with a lower cost',
        competitiveRate1: 'Innovation',
        competitiveRate2: 'Quality assurance',
        competitiveRate3: 'Continued improvement',
        provincialCity25: '25 privincial & Cities, 854 branches crossing Cambodia',
        provincialCity25List1: 'Flexibility: booking call, delivey, COD, D2D',
        provincialCity25List2: 'Paid / Unpaid',
        provincialCity25List3: 'Any time, any where',
        safetyCerification: 'Safety certification, Insurance, compensation',
        safetyCerificationList1: 'Technology: every transactions are on technology and on time',
        safetyCerificationList2: 'On time tracking your transfer at any time any where on your mobile',

        vetVehicletype: 'VET Vehicles Type',
        vetVehicletype1: 'Bus / Van',
        vetVehicletype2: 'Trucks',
        vetVehicletype3: 'Speed Boat',

        vetSubsidiaries: 'VET Subsidiaries',
        vetSubsidiaries1: '1. The resorts that founded by Neak Oknha Suo Vireak',
        vetSubsidiaries2: 'Other companies that founded by Neak Oknha Suo Vireak',


        crossBorderInformationOrocedure: 'Cross Border Information & Procedure',
        crossBorderTitle1: 'For Entry to Vietnam from Cambodia:',
        crossBorderText1: 'Passengers who have applied for a Vietnam e-visa will be required to pay an additional US$1 if the visa is not printed out; this fee covers the printing at the immigration office. The additional charge has led to confusion among passengers, but we assure you that it is not a scam. Customers who pay the US$1 fee can enjoy lunch, relax, or watch a movie. Alternatively, they can go with our staff to complete the transaction.',
        
        crossBorderTitle2: 'VIP Cross-Border Service Process:',
        crossBorderText2: `Our VIP service ensures a swift immigration process. Leveraging the relationships, we've built with customs officials, we guarantee a priority service that enhances efficiency. While we can't bypass queues, we assure a streamlined process for our passengers`,
        
        crossBorderTitle3: 'Border Crossing',
        crossBorderText3: 'Applying for visas and crossing borders can be confusing, especially for first-time travelers. One of the major benefits of traveling with VET Air Express is to make your travel hassle-free. Of course, crossing borders comes with its own entanglements. To make your travel stress-free, our staff works behind the scenes while you relax on bus or watching movie or enjoy our set lunch in the bus to assure a straightforward border crossing.',

        crossBorderTitle4: 'Phnom Penh Capital to Ho Chi Minh City and Vice Versa Trip Procedure',
        crossBorderText4: `At the departure terminal, the passenger's passport will be scanned and sent to VET Air Bus Express's staff at the border to pre-complete the exit and entry form. The border crossing for entry between the two countries is at Bavet /Moc Bai. Many nationalities are required to apply for a Visa before entering Vietnam and Cambodia, with the exception of some European and Asian countries (See Appendix Below). Several passport holders receive the Visa on arrival. It is the duty of passengers to check before booking their journey.`,
        crossBorderText41: 'Before reaching the border, staff will collect the passenger’s passport to process the exit visa stamping and entry stamping with the customs officer',

        crossBorderTitle5: 'The Process at the Border',
        crossBorderText5: 'Once the bus arrives at the border, passengers are required to get off and follow the staff to immigration. VET Air Bus Express ticket fees include a VIP service, which assures passengers lead through immigration as hastily as possible. The border-crossing time change depending on the number of people crossing the border.',
        crossBorderText51: 'Passengers must first transit the country of origin’s immigration to exit the country then take the bus for short ride to the destination country’s customs to officially enter the country. Passengers will be requested to take their luggage off the bus to be scanned by customs officers of the destination country then get back on bus.',

        crossBorderTitle6: 'Phnom Penh Capital to Bangkok City and Vice Versa Trip Procedure',
        crossBorderText6: `At the departure terminal, the passenger's passport will be scanned and sent to VET Air Bus Express's staff at the border to pre-complete the exit and entry form. The border crossing for entry between the two countries is at Poipet/ Aranyaprathet. Most of nationalities are required to apply for a Visa before entering to Cambodia, with the exception of ASEAN countries. All passport holders able to receive the Visa on arrival. For enter to Thailand most of nationalities are free visa, with the exception of some countries are required to apply visa before entering to Thailand (See Appendix Below). It is the duty of passengers to check before booking their journey.`,
        crossBorderText61: 'Before reaching the border, staff will collect the passenger’s passport to process the exit visa stamping and entry stamping with the customs officer.',

        crossBorderTitle7: 'The Process at the Border',
        crossBorderText7: 'Once the bus arrives at the border, passengers are required to get off and follow the staff to immigration. VET Air Bus Express ticket fees include a VIP service, which assures passengers lead through immigration as hastily as possible. The border-crossing time change depending on the number of people crossing the border.',
        crossBorderText71: 'Passengers must first transit the country of origin’s immigration to exit the country then take the bus for short ride to the destination country’s customs to officially enter the country. Passengers will be requested to take their luggage off the bus to be scanned by customs officers of the destination country then get back on bus.',

        crossBorderTitle8: 'For Entry to Cambodia from Vietnam only',
        crossBorderText8: 'Passengers who wish to apply visa on arrival will be required to pay an additional US$10 and can relax on bus or watching movie or have lunch/dinner. Otherwise, they can go with the staff to make the transaction themselves.',

        crossBorderTitle9: 'For Entry to Cambodia from Thailand only',
        crossBorderText9: 'Passengers who wish to apply visa on arrival will be required to pay an additional 200 BAT and can relax on bus or watching movie or have lunch/dinner. Otherwise, they can go with the staff to make the transaction themselves, required 3 photos 4 x6 and another supporting document.',

        crossBorderTitle10: '*Note:',
        crossBorderText10: 'The additional fee has caused a lot of confusion from passengers querying the US$10 / 200 BAT charge. We want to reassure passengers that this is not a scam. Note that customers who pay US$10/200BAT can then enjoy lunch or watching movie or relax. Alternatively, they can go with our staff to make the transaction themselves.',

        crossBorderTitle11: 'Appendix:',
        crossBorderTitle111: 'Nationalities with Free Visa to Vietnam:',
        crossBorderText11: 'A. Citizens of 13 countries enjoy a tourist-free visa for a 15-day stay in Vietnam, including Germany, France, Italy, Spain, the UK and Northern Ireland, Russia, Japan, South Korea, Denmark, Sweden, Norway, Finland, and Belarus.',
        crossBorderText111: 'B. 10 ASEAN countries have a free visa for a one-month stay in Vietnam: Cambodia, Laos, Malaysia, Indonesia, the Philippines, Thailand, Brunei Darussalam, Singapore, Timor Leste, and Myanmar.',

        crossBorderTitle12: 'Nationalities Needing Visa for Thailand:',
        crossBorderText12: '18 Nationalities require a visa for Thailand, such as Bulgaria, Bhutan, China, Cyprus, Fiji, Georgia, India, Kazakhstan, Malta, Mexico, Nauru, Papua New Guinea, Romania, Saudi Arabia, Taiwan, Uzbekistan, Vanuatu, and Myanmar.',
        crossBorderText121: 'All nationalities can get a visa on arrival except Myanmar, which needs to obtain a visa before boarding the bus.',


        loginAccount: 'Login Account',
        pleaseEnterYourCredentialToLogin: 'Please enter your credential to login!',
        pleaseEnterYourCredentialToRegister: 'Please enter your credential to register',
        emailTelephoneNumber: 'Email / Telephone number',
        enterPassword: 'Enter password',
        forgotPassword: 'Forgot Password?',
        donotHaveAccountRegister: `Don't have account?`,
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        enterFullName: 'Enter Full Name',
        nationality: 'Nationality',
        selectNationality: 'Select nationality',
        enterTelephoneNumber: 'Enter your telephone number',
        gender: 'Gender',
        male: 'Male',
        Female: 'Female',
        pleaseEnterYourEmailTelephoneNumber: 'Please enter your email / telephone number!',
        myProfile: 'My Profile',
        personalInformation: 'Personal Information',
        emailAddress: 'Enter your email',
        edit: 'Edit',
        address: 'Address',
        dateOfBirth: 'Date of Birth',
        accountTicket: 'Account Ticket',
        generalAccount: 'General Account',
        condition: 'Condition',

        departure: 'Departure',
        duration: 'Duration',
        arrival: 'Arrival',
        boarding: 'Boarding',
        boardingPoint: 'Boarding Point',
        tripInfo: 'Trip Info',
        dropOff: 'Drop Off',
        dropOffPoint: 'Drop Off Point',
        seat: 'Seat',
        seats: 'Seats',
        direct: 'Direct',
        borderTransit: 'Border Transit',
        road: 'Raod',
        roadNo_: 'Raod No.',
        expressWay: 'Express Way',
        roadNumber: 'Raod Number',
        moreInformationAboutBus: 'More Information About Bus',
        amenities: 'Amenities',
        crewAttendant: 'Crew Attendant',
        dinkingWater: 'Drinking Water',
        gps: 'GPS',
        insurance: 'Insurance',
        lifeJacket: 'Life Jackets',
        toilet: 'Toilet',
        trainedCaptains: 'Trained Captains',
        trainedDrivers: 'TrainedDrivers',
        wifi: 'WiFi',
        description: 'Description',
        left: 'Left',
        full: 'Full',

        seatAvailable: 'Seat Available',
        seatSelected: 'Seat Selected',
        seatUnavilable: 'Seat Unavailable',
        downStair: 'Down Stair',
        upStair: 'Up Stair',
        vehicleType: 'Vehicle Type',
        price: 'Price',
        selectedSeat: 'Selected Seat',
        seatNumber: 'Seat Number',
        totalFare: 'Total fare',
        continue: 'Continue',
        next: 'Next',

        passengerDetail: 'Passenger Details',
        enterYourfullName: 'Enter your full name',
        location: 'Location',
        billingDetail: 'Billing Details',
        yourBookingDetailticketWillBeSent: 'Your booking detail ticket will be sent your email address',
        attention: 'Attention',
        desAttention: 'Information for Sleeper Bus Beds are designed for two people. We are sorry if your bed has difference gender, we will change.',
        desBoardingPointNight: 'Please make sure you come to the correct boarding point that you have chosen as some schedules do not have same price. In case you chose the boarding point with lower price and come to boarding point with higher price, we will charge extra.',
        fareDetail: 'Fare Detail',
        direction: 'Derection',
        departureTime: 'Departure Time',
        arrivalTime: 'Arrival Time',
        grandTotal: 'Grand Total',
        subTotal: 'Sub Total',
        discount: 'Discount',
        free1: 'Free 1',
        total: 'Total',
        totalSummary: 'Total Summary',
        luckyTicket: 'Lucky Ticket',
        luckyTicketTitle: 'Ready to Win? Get Your Lucky Ticket Now!',
        desLuckyTicket: 'Please tick if you are feeling lucky to turn that luck into real rewards with our exciting Lucky Ticket!',
        subDesLuckyTicketAdd: 'Add',
        desLuckyTicketSpanBlod:'ONLY $0.25',
        subDesLuckyTicket: 'extra on top of the ticket price for chance to win monthly grand prizes. Visit our',
        subDesLuckyTicket1: 'for details.',
        desLuckyTicketSpan: 'Facebook page',
        desLuckyTicket1: 'for details.',
        applyTravelPackageCode: 'Apply Travel Package Code',
        applyCouponCode: 'Appy Coupon Code',
        counponCode: 'Coupon code',
        enterYourCouponCode: 'Enter your coupon code',
        apply: 'Apply',
        desTermCoditionPrivacyPolicy: `By clicking on 'Process Payment', you agree to the`,
        desTermCoditionPrivacyPolicyAnd: 'and',
        processPayment: 'Process Payment',

        cancelBooking: 'Cancel Booking',
        orderId: 'Order ID',
        completeYourPaymentbeforetimeout: 'Complete your payment before timeout',
        numberOfPassenger: 'Number of passenger(s)',
        paymentSummary: 'Payment Summary',
        choosePaymentMethod: 'Choose Payment Method',
        paymentMethod: 'Payment Methods',
        payNow: 'Pay Now',

        abakhqr: 'ABA KHQR',
        desabakhqr: 'Scan to pay with member bank app',
        creditDebitCard: 'Credit / Debit Card',
        wingBank: 'Wing Bank',
        desWingBank: 'Pay securely with Wing Bank',

        whyChooseTravelPackage: 'Why choose travel package?',
        desWhyChooseTravelPackageLess: `Are you a travel enthusiast with an ever-itchy foot? Look no further! Our Travel Package offers unparalleled opportunities for unforgettable experiences. Let us explain why our package is the perfect choice for your next adventure:`,
        desWhyChooseTravelPackageMore: `Are you a travel enthusiast with an ever-itchy foot? Look no further! Our Travel Package offers unparalleled opportunities for unforgettable experiences. Let us explain why our package is the perfect choice for your next adventure: <br /><br />Cost-Effective: Great value for your money with best rate offer.
        <br />Variety of Destinations: From 25 provinces in Cambodia, Thailand, Vietnam and Laos.<br />Peace of Mind: Safety and comfort ensured.<br />Customization: Tailored to your preferences of Air Bus, VIP Van, Luxury Bus, Rental Car and Speed Boat any dates within your package validity. 
        Unforgettable Experiences: Memories that last a lifetime.<br /><br />Book now and embark on your dream adventure!`,

        bookingId: 'Booking ID',
        bookingDate: 'Booking Date',
        paymentType: 'Payment Type',
        tripDetail: 'Trip Detail',
        ticketCode: 'Ticket code',
        destinationFrom: 'Destination From',
        travelDate: 'Travel Date',
        destinationTo: 'Destination To',
        departureAddress: 'Departure address',
        dropOffAddress: 'Drop off address',
        viewMap: 'View Map',
        desResendEmail: `If you haven't received your E-ticket via email, please click button resend your email.`,
        rendEmail: 'Resend Email',
        thaxUrPayment: `Thank you, your payment has been successful. A confirmation email has been send to`,
        paymentComplete: 'Payment Completed',

        yourTicketHasBooked: 'Your Ticket Has Been Booked',
        desYourTicketHasBooked: `Please arrives the boarding station 30 minutes before your booking time. Please check your your booking ticket in option ticketing history`,
        showTicket: 'Show Ticket',

        registerInfo: 'Register Info',
        confirmPackage: 'Confirm Package',
        confirmPayment: 'confirm Payment',
        addImage: 'Add image',
        buyPackage: 'Buy Package',
        cancellationPolicy: 'Cancellation Policy',
        plsShowQRCodeToBranchStaffScan: 'Please show the QR Code to the branch staff to scan',
        viewQRCode: 'View QR Code',
        search: 'Search',

        // require, not found
        dataNotFound: 'Data Not Found',
        noSchedule: 'No Schedule',
        yourTelephoneNumberInvalid: 'Your telephone number is invalid',
        yourTelephonNumberRequire: 'Your telephone number is required',
        yourEmailInvalid: 'Your email is invalid',
        yourEmailRequire: 'Your email is required',
        yourEmailTelephoneNumberInvalid: 'Your email / telephone number is ivalid',
        yourEmailTelephoneNumberRequired: 'Your email / telephone number is required',
        yourGenderInvalid: 'Your gender is invalid',
        yourGenderRequired: 'Your gender is required',
        yourNationalityInvalid: 'Your nationality is invalid',
        yourNationalityRequired: 'Your nationality is required',
        yourFullNameRequired: 'Your full name is required',
        yourPasswordInvalid: 'Your password is invalid',
        yourPasswordRequired: 'Your password is required',
        yourConfirmPasswordNotMatch: 'Your confirm password not match',
        yourConfirmPasswordRequired: 'Your confirm password is required',
        yourPasswordHaveDigit: 'Your password must be 4 digit',
        plsShowUrETicket: 'Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket',
        codeInvalid: 'Code Invalid',
        desCodeInvalid: 'Please check coupon code again!',
        plsSelectDepartingFrom: 'Please select departing from',
        plsSlectGoingTo: 'Please select going to',

        // terms and condition
        TermCon:"Terms & Condition",
        tc1:"Passengers are required to arrive at the boarding point 30 minutes before the scheduled departure time for check in.",
        tc2:"It is the passengers' responsibility to ensure early or on-time arrival at the boarding station. Missed schedules will not be eligible for refunds. The purchase tickets will be expired after the bus departure.",
        tc3:"Purchased tickets are non-refundable but can be used as a coupon for future trips. Coupon refunds can be processed up to 12 hours before departure schedule. The Coupon valid three months this reschedule can be made only time with the same route and vehicle type.",
        tc4:"For crossing border Vireak Buntham Express Travel is not responsible for VISA entry to the country, in case any immigration rejects at the border, passenger will not eligible for refund the ticket.",
        tc5:"Infants under the age of 3 are not required to have tickets. They must be accompanied by an adult and sit on their lap. Children aged 3 and above must purchase their own seats at the full price. But for sleeping bus infant require to have their bed.",
        tc6:"Passengers can choose their preferred seats and purchase real-time tickets through the VET Express Mobile APP or website at any time before schedule.",
        tc7:"Passengers are allowed a maximum of 25kg of checked baggage and one carry-on item. Additional charges will apply for overweight baggage, based on the local delivery fee.",
        tc8:"Items such as strong-smelling food (e.g., durian), pets, firearms, narcotics, smuggled or illegal products, and other dangerous or flammable/explosive items are strictly prohibited from being brought onboard.",
        tc9:"Passengers are responsible for the safety of their personal belongings and valuables. Vireak Buntham Express Travel will not be held liable for any loss or damage to personal items.",
        tc10:"If you haven't received your E-ticket via email, we kindly ask you to click the button labeled 'Resend Email' after your payment has been completed. Alternatively, please also check your spam inbox. Should you still not receive your e-ticket, please don't hesitate to reach out to us via email at support@vireakbuntham.com or by calling our hotline at (+855) 81 911 911. Please be aware that passengers will not be eligible to request a refund for the ticket if an E-ticket has not been received.",
    }
}