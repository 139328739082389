import Vue from 'vue';
import router from "./router";
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './all.css';
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from 'vue-browser-geolocation';
import SsrCarousel from 'vue-ssr-carousel';
import 'vue-ssr-carousel/index.css';
import 'pretty-checkbox';
import Vuelidate from 'vuelidate';
import VueGtm from '@gtm-support/vue2-gtm';

// import VueFbCustomerChat from 'vue-fb-customer-chat'
// import './locales/index';
import i18n from './locales/index.js';
Vue.component('ssr-carousel', SsrCarousel);
Vue.use(Vuelidate);
// Vue.use(VueFbCustomerChat, {
//   page_id: 546383182555885, //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

Vue.use(VueGeolocation);

Vue.config.productionTip = false

Vue.prototype.$trips = 'trips';
Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);

Vue.prototype.$url = 'https://vetapim.utlog.net/vetTkBusWebApi/'; // production
// Vue.prototype.$url = 'https://qacltom.udaya-tech.com/vetTkWBusApi/'; // qa
// Vue.prototype.$url = 'http://192.168.1.6:8388/vetTkWebsiteApi/'; //local
Vue.prototype.$header = {
  "Authorization": "Bearer d5cfe6a0-48f3-4c20-b1dd-53a395e064c5",// production
  // "Authorization": "Bearer 4d60b15b-a163-4f94-a1f8-488e26dad830", // qa
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
};
//upload file
Vue.prototype.$secret = '4d60b15b-a163-4f94-a1f8-488e26dad830'; //local

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAwoyIaiEWWPUb59IT7tJS1vxqijIaCSPE",
  },
});

Vue.use(VueGtm, {
  id: 'GTM-PCTZSHNV',
  vueRouter: router,
});

Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
});

new Vue({
  vuetify,
  router,
  i18n,
  linkActiveClass: 'active-route',
  render: h => h(App)
}).$mount('#app')